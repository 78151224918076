<template>
  <div class="paymentSuccess">
    <img class="icon-wode" src="@/assets/组 8406.png" alt="" />
    <div class="successText">支付成功</div>
    <div class="btn-wrapper">
      <div class="cost" @click="goText">继续对话</div>
      <div class="order" @click="goMy">我的订单</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  metaInfo: {
    title: "支付成功",
  },
  methods: {
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
    goMy() {
      //老板看报告
      this.$router.push({
        name:'wx-myOrders'
      });
    },
    goText() {
      console.log("goText")
      //继续对话
      wx.miniProgram.navigateTo({
        url: "/pages/Im/Im",
      });

    },
  },
};
</script>

<style lang="stylus" scoped>
.icon-wode{
    width:4rem;
    height:2.4rem;
    margin-top: 2.38rem;
}
.paymentSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    .successText{
      margin-top: 0.8rem
      font-size: 0.36rem;
      font-weight: 600;
    }
}
.btn-wrapper {
    display: block;
    //align-items: center;
    //margin-top: 1.2rem;

    .order, .cost {
      width: 4rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: rgba(63, 116, 246, 0.2);
      border-radius: 0.1rem;
      color: #3F74F6;
      margin-top :20px;
      font-size: 0.32rem;
      text-align: center;
    }

    .cost {
      //margin-left: 0.4rem;
      margin-top :1.2rem;

      background: #165DFF;
      opacity: 1;
      color: #fff;
    }
  }
</style>
