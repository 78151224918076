<template>
  <div class="my">
    <div class="fixBottom" v-show="showFixBottom">
      <span>可登录到电脑端查看报告</span>
      <div>
        <div class="buttons" @click="copy">复制登录地址</div>
        <!-- <div class="url" v-show="false" id="copyInner">www.console.ai-tax.cn</div> -->
        <i class="iconfont icon-icon_close" @click="showFixBottom=false"></i>
      </div>
    </div>
    <card class="card" @changeActive="changeActive"></card>
    <div class="card-item card-item-pay" v-if="active == 1">
      <div class="pay-title">
        <div :class="{ payActive: payStatusType == 0 }" @click="payStatus(0)">
          全部({{ payListTotal.length }})
        </div>
        <div :class="{ payActive: payStatusType == 1 }" @click="payStatus(1)">
          待支付({{ payListWaitForPay.length }})
        </div>
        <div :class="{ payActive: payStatusType == 2 }" @click="payStatus(2)">
          已完成({{ payListSuccess.length }})
        </div>
        <div :class="{ payActive: payStatusType == -1 }" @click="payStatus(-1)">
          已取消({{ payListCancel.length }})
        </div>
      </div>
      <div v-if="payList && payList.length > 0">
        <template v-for="item in payList">
          <pay-item class="pay-item" :item="item"></pay-item>
        </template>
      </div>
      <div v-else class="nodata">
        <img src="@/assets/no-data.png" alt="" />
        <div class="nodata-title">还没有提交过订单信息哦～</div>
        <div class="nodataBtn" @click="toIndex">立即购买</div>
      </div>
    </div>
    <div class="card-item" v-if="active == 2">
      <tryInfo class="try-input"></tryInfo>
      <div class="qr-code">
        <div class="wx-code">
          <img src="@/assets/link-code.png" alt="" />
        </div>
        <div>长按二维码添加专属客服！</div>
        <div class="phone">联系电话：0571-85055930</div>
      </div>
    </div>
    <div class="card-item" v-if="active == 3">
      <van-swipe :loop="false" ref="swipe" class="swipeImg" :stop-propagation="false">
        <template v-for="(item, index) in bankData">
          <van-swipe-item v-if="index % 2 == 0" :key="index">
            <div style="display: flex; justify-content: space-between; margin: 0 0.2rem">
              <div
                class="cardList"
                :id="'colorList' + index"
                @touchmove="onTouchMove"
                @touchstart="onTouchStart"
                @click="goDetection(bankData[index])"
              >
                <div class="top-img"
                     :style="{background:'url('+item.showImageUrl+')','background-size':'100% 100%'}">
                  <img class="version-logo"
                       :src="getCornerPic(item.skuAttr)"
                       alt="">
                  <div class="mask-content">
                    <p>{{getAttrVal(bankData[index].skuAttr,'chargeMode') }}</p>
                    <p>有效期：{{ getAttrVal(bankData[index].skuAttr,'validity') }} &nbsp;&nbsp;<span>可用数：{{ bankData[index].usableNum  }}</span>
                    </p>
                  </div>
                </div>
                <div class="bckDiv">
                  <p>{{bankData[index].goodsName}}</p>
                  <span class="ImmediateDetection">立即检测</span>
                </div>
              </div>
              <div
                v-if="bankData[index + 1]"
                class="cardList"
                :id="'colorList' + [index + 1]"
                @touchmove="onTouchMove"
                @touchstart="onTouchStart"
                @click="goDetection(bankData[index+1])"
              >
                <div class="top-img"
                     :style="{background:'url('+  bankData[index+1].showImageUrl+')','background-size':'100% 100%'}">
                  <img class="version-logo"
                       :src="getCornerPic(bankData[index+1].skuAttr)"
                       alt="">
                  <div class="mask-content">
                    <p>{{getAttrVal(bankData[index+1].skuAttr,'chargeMode') }}</p>
                    <p>有效期：{{ getAttrVal(bankData[index+1].skuAttr,'validity') }} &nbsp;&nbsp;<span>可用数：{{ bankData[index+1].usableNum  }}</span>
                    </p>
                  </div>
                </div>
                <div class="bckDiv">
                  <p>{{ bankData[index+1].goodsName}}</p>
                  <span class="ImmediateDetection">立即检测</span>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </template>
      </van-swipe>
      <div class="mobileIcon">
        <img
          v-if="showArrow"
          class="arrow-left"
          src="@/assets/arrow_left.png"
          @click="changeSwiper('left')"
          alt=""
        />
        <img
          v-if="showArrow"
          class="arrow-right"
          src="@/assets/arrow_right.png"
          @click="changeSwiper('right')"
          alt=""
        />
      </div>
      <div class="detection">检测记录</div>
      <div class="nullPic" v-if="dataPage == ''">
        <img src="@/assets/暂无数据.png" alt="" />
        您还没有发起过风险检测哦～
      </div>
      <div
        v-else
        class="detectionCard"
        v-for="(item, index) in dataPage"
        :key="index"
        :class="index == 0 ? 'topStyle' : ''"
      >
        <div class="cardTime" @click="clickStatusTip(item)">
          <div class="haibaoPic">
            <img class="backPic" :src="item.showImageUrl" alt="" />
            <img class="version-logo"
                 :src="getCornerPic(item.attrs)"
                 alt="">
          </div>
          <div style="margin-left: 0.3rem">
            <div class="timeOne">{{ item.taxName }}</div>
            <div class="timeTwo">
              {{ item.goodName }}
            </div>
            <div class="timeThree">{{ item.createTime }}</div>
          </div>
          <div class="status">
            <div class="statusBlue" v-if="item.status == 0 || item.status == 1">
              <div class="statusCircleBlue"></div>
              {{ statusList.get(item.status)}}
            </div>
            <div class="statusGray" v-else-if="item.status ==-2 ">
              <div class="statusCircleGray"></div>
              {{ statusList.get(item.status)}}
            </div>
            <div class="statusYellow" v-else-if="item.status ==3 ">
              <div class="statusCircleYellow"></div>
              {{ statusList.get(item.status)}}
            </div>
            <div class="statusGreen" v-else>
              <div class="statusCircleGreen"></div>
              {{ statusList.get(item.status)}}
            </div>
          </div>
        </div>
        <!-- </router-link> -->
      </div>
    </div>
    <div class="" v-if="active == 4">
      <promotionPoster></promotionPoster>
    </div>
    <div>
      <van-dialog
        v-model="showTitle"
        title="温馨提示"
        show-cancel-button
        confirmButtonText="立即购买"
        cancelButtonText="稍后再说"
        @confirm="goProductOrder"
        className="VantDialog"
        messageAlign="center"
      >
        <div>当前剩余检测次数为 0</div>
      </van-dialog>
    </div>
    <van-popup
      v-model="vanPopupShareShow"
      closeable
      round
      position="bottom"
      :style="{ height: 'calc(100vh - 100px)' }"
    >
      <div class="shareDiv">
        <div class="shareTitle">报告详情</div>
        <div class="toptips">
          <i class="iconfont icon-icon_warn"></i>
          <div>
            《专业版3.0》报告内容交互较多，当前查看报告具体内容需登录电脑端系统，请“复制链接”后按以下步骤操作!
          </div>
        </div>
        <div class="shareInput">
          <el-input placeholder="请输入内容" v-model="loginUrl" style="width: 345px">
            <el-button
              slot="append"
              style="background: #165dff; color: #fff; border-radius: 0px 8px 8px 0px"
              @click="loginMsgdial"
            >复制链接
            </el-button
            >
          </el-input>
        </div>
        <div class="gekai"></div>
        <div class="sharePng">
          <img src="@/assets/step.png" />
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";

import card from "./components/card.vue";
import payItem from "./components/pay-item.vue";
import tryInfo from "./components/try-info.vue";
import promotionPoster from "./components/promotionPoster.vue";
import { channelOrdOrderPage } from "@/api/fri";
import { getCheckVersionDataApi, modelCheckGetPageApi } from "@/api/poster";
import { getAttrVal, getCornerPic } from '@/utils/help.js'

const baseEnv = process.env.VUE_APP_GOLD_REPORT_VIEW;
import { copyTextForText } from "@/utils/help.js"

export default {
  metaInfo: {
    title: "我的",
  },
  components: {
    card,
    payItem,
    tryInfo,
    promotionPoster,
  },
  data () {
    return {
      statusList: new Map([[0, '生成中'], [1, '生成中'], [2, '已生成'], [-2, '已删除'], [3, '未授权']]),
      showFixBottom: true,
      active: 3,
      payStatusType: localStorage.getItem("payStatusType") || 0,
      payStatusList: ["", "", 2, -1],
      payList: null,
      payListSuccess: 0,
      payListWaitForPay: 0,
      payListCancel: 0,
      payListTotal: 0,
      tabPosition: "top",
      bankData: {},
      showStatus: "",
      dataPage: {},
      showArrow: true,
      showTitle: false,
      showReportTitle: false,
      id: "",
      show: false,
      text: "https://console.ai-tax.cn",
      clickFlag: true,
      vanPopupShareShow: false,
      loginUrl: "",
      reportId: "",
    };
  },
  mounted () {
    this.init();
    this.getPageLength();
  },
  methods: {
    getAttrVal, getCornerPic,
    copy () {
      copyTextForText('https://console.ai-tax.cn/');

    },
    loginMsgdial () {
      var copyTest = this.loginUrl;
      var inputTest = document.createElement("input");
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = "oInput";
      inputTest.style.display = "none";
      Toast("复制成功");
    },
    onTouchStart () {
      this.clickFlag = true;
    },
    onTouchMove () {
      this.clickFlag = false;
    },
    changeSwiper (val) {
      if (val == "right") {
        this.$refs.swipe.next();
      } else {
        this.$refs.swipe.prev();
      }
    },
    init () {
      // 获取可检测版本和次数
      let channelUniqueId = localStorage.getItem("channelUniqueId");
      const currentOrganizationId = localStorage.getItem("organizationId");
      getCheckVersionDataApi({
        organizationId: currentOrganizationId,
        displayPlatform: "1",
      }).then((res) => {
        if (res.code == 200) {
          this.bankData = res.data;
          if (res.data.length == "0" || res.data.length == "1" || res.data.length == "2") {
            this.showArrow = false;
          }
        }
      });

      let params = {
        companyName: "",
        endTime: "",
        organizationId: currentOrganizationId ? currentOrganizationId : "",
        pageNum: 1,
        pageSize: 10000,
        status: "",
        startTime: "",
        // channelUniqueId: channelUniqueId ? channelUniqueId : "",
      };
      modelCheckGetPageApi(params).then((res) => {
        this.dataPage = res.data.list;
      });
    },
    // groupList(bankData) {

    //   const arr = [];
    //   for (var i = 0; i < bankData.length; i += 2) {
    //     console.log(i);
    //     arr.push(bankData.slice(i, i + 2));
    //   }
    //   return arr;
    // },
    clickStatusTip (v) {
      // console.log();
      // if (v.goodsId != 20) {
      //   this.show = true;
      //   return;
      // }

      if (v) {
        localStorage.setItem("goodsName", v.goodsName);
      }
      if (v.status == 2) {
        if (v.goodsId == 20) {
          //去老板看报告
          this.$router.push({
            path: "/userOnly/boss-watch-report/" + v.programId + "/" + v.reportId,
            query: {
              // id: this.goodsList.policyId,
              channelUniqueId: localStorage.getItem("channelUniqueId"),
              code: this.$route.query.code,
            },
          });
          return;
        } else {
          console.log(v);
          this.loginUrl = baseEnv + 'reportCenter/' + v.reportId + '?token=' + localStorage.getItem('token') + '&desensitizationStatus=0'
          window.open(this.loginUrl)
          // this.loginUrl = baseEnv;
          // this.reportId=v.id
          // this.vanPopupShareShow = true;
          return;
        }
      } else if (v.status == 0 || v.status == 1 || v.status == 3) {
        const params = {
          companyName: v.taxName,
          taxNo: v.taxNo,
          orgId: localStorage.getItem("organizationId"),
          goodId: v.goodsId,
          zq: v.zqId.replace('004', ''),
          source: 3,
          uniqueId: localStorage.getItem("channelUniqueId"),
          endTime: v.fxqjZ,
          startTime: v.fxqjQ,
        };
        this.$router.push({
          name: "dataAuthorization",
          params: {
            uuid: v.programId,
            reportId: v.reportId,
          },
          query: {
            info: JSON.stringify(params),
            channelUniqueId: localStorage.getItem("channelUniqueId"),
          },
        });
        return;

      }
      this.showReportTitle = true;
    },
    goDetection (v) {
      if (v.usableNum < 1) {
        Toast('可用检测次数为0，请先购买。');
        return;
      }
      this.$router.push({
        path: "/orderInformation",
        query: {
          goodInfo: JSON.stringify(v),
          skuId: v.skuId,
          goodsId: v.goodsId,
          channelUniqueId: localStorage.getItem("channelUniqueId"),
          code: this.$route.query.code,
        },
      });

    },
    goProductOrder () {
      this.$router.push({
        path: "/order",
        query: {
          id: this.id,
          channelUniqueId: localStorage.getItem("channelUniqueId"),
          code: this.$route.query.code,
        },
      });
    },
    toIndex () {
      this.$router.back();
    },
    changeActive (val) {
      this.active = val;
      if (val === 1) {
        this.getPageLength();
        this.payStatus(0)
      } else if (val === 3) {
        this.init()
      }
    },
    payStatus (val) {
      this.payStatusType = val === 0 ? '' : val;
      localStorage.setItem("payStatusType", this.payStatusType);
      this.getPage();
    },
    getPage () {
      let channelUniqueId = localStorage.getItem("channelUniqueId");

      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        channelName: "",
        endDate: "",
        orderNo: "",
        orderStatus: this.payStatusType, // 状态(-1 订单取消;0=订单初始状态 1=待支付 2=已支付)
        pageNum: 0,
        pageSize: 10000,
        phonenumber: "",
        startDate: "",
        channelUniqueId,
      };
      // 获取渠道订单信息表分页列表
      channelOrdOrderPage(params).then((res) => {
        this.loading.close();
        if (res.code == 200) {
          this.payList = res.list;
        }
      });
    },
    getPageLength () {
      let channelUniqueId = localStorage.getItem("channelUniqueId");

      let params = {
        orderStatus: "", // 状态(-1 订单取消;0=订单初始状态 1=待支付 2=已支付)
        pageNum: 0,
        pageSize: 10000,
        channelUniqueId,
      };
      // 获取渠道订单信息表分页列表
      channelOrdOrderPage(params).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.payListSuccess = res.list.filter((item) => item.orderStatus == 2);
          this.payListCancel = res.list.filter((item) => item.orderStatus == -1);
          this.payListWaitForPay = res.list.filter((item) => item.orderStatus == 1);
          this.payListTotal = res.list;
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.fixBottom {
  z-index: 999;
  font-size: 14px;
  border-radius: 4px;
  position: fixed;
  bottom: 0.4rem;
  left: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 7.1rem;
  height: 0.8rem;
  box-sizing: border-box;
  padding: 0.2rem 0.24rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  > div {
    display: flex;
    align-items: center;
    .buttons {
      background: #fff;
      width: 1.84rem;
      border-radius: 4px;
      font-size: 12px;
      height: 0.56rem;
      color: #1D2129;
      text-align: center;
      line-height: 2.5;
      margin-right: 0.31rem;
    }
    i {
      font-size 0.2rem;
      color: #fff
    }
  }
}
>>> .van-overlay {
  z-index: 1000 !important;
}
.shareDiv {
  .shareTitle {
    box-sizing: border-box;
    padding: 14px;
    height: 54px;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #1D2129;
  }
  .shareInput {
    box-sizing: border-box;
    padding: 20px;
    //display:flex;
  }
  .sharePng {
    padding: 15px;
    box-sizing: border-box;
    overflow: auto;
    height: 50vh;
    > img {
      width: 100%;
    }
  }
  .gekai {
    width: 100%;
    height: 10px;
    background: #F4F6F8;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  >>> .el-input-group__append {
    border-radius: 8px;
  }
}
.my {
  padding-bottom: 0.3rem;
  background: #F5F7FA;
}
.card-item {
  margin-top: 0.7rem;
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 3.6rem;
      margin-top: 1.2rem;
      margin-bottom: 0.2rem;
    }
    .nodata-title {
      line-height: 0.42rem;
      color: #0E1429;
      font-size: 0.3rem;
    }
    .nodataBtn {
      width: 4rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: #165DFF;
      border-radius: 0.1rem;
      opacity: 1;
      color: #fff;
      text-align: center;
      margin-top: 0.8rem;
    }
  }
  .try-input {
    margin-left: 0.2rem;
  }
  .pay-title {
    display: flex;
    justify-content: space-around;
    //padding: 0.16rem 0 0.28rem 0;
    color: rgba(14, 20, 41, 1);
    font-size: 0.28rem;
    font-weight: 400;
    align-items: center;
    margin-bottom 0.28rem
    padding-top 0.36rem;
    .payActive {
      color: rgba(63, 116, 246, 1);
      font-weight: bold;
      font-size: 0.32rem;
    }
  }
}
.qr-code {
  background: url("../assets/qrCode.png") no-repeat center 0px;
  background-size: cover;
  width: 6.9rem;
  height: 4.1rem;
  margin-top: 1rem;
  margin-left: 0.3rem;
  font-size: 0.26rem;
  text-align: center;
  padding-top: 0.5rem;
  box-sizing: border-box;
  .wx-code {
    width: 1.8rem;
    height: 1.8rem;
    background: #fff;
    margin: 0 auto;
    margin-bottom: 0.36rem;
    img {
      width: 100%;
    }
  }
  .phone {
    color: #0E1429;
    margin-top: 0.2rem;
    line-height: 0.38rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-size: 0.26rem;
  }
}
.card-item-pay {
  margin-left: 0.2rem;
}
.detection {
  //margin: 0.4rem 0.3rem 0.3rem;
  font-size: 0.32rem;
  font-weight: bold;
  color: #0E1429;
  padding-left: 0.3rem
  line-height: 48px;
}
>>> .el-radio-button__inner {
  width: 1.22rem;
  height: 0.64rem;
  font-size: 0.24rem;
  font-weight: bold;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
>>> .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #E0E9FE;
  border-radius: 10px;
  border: none;
  font-size: 0.24rem;
  font-weight: bold;
  color: #3F74F6;
  box-shadow: none;
}
>>> .el-radio-group label {
  margin-right: 0.2rem;
}
>>> .el-radio-button:first-child .el-radio-button__inner {
  border-left: none;
  border-radius: 10px;
}
>>> .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 10px;
}
.detectionCard {
  //width: 7.10rem;
  //height: 2.68rem;
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  margin: 0.3rem 0.2rem 0
  padding: 0.2rem;
  box-sizing: border-box;
  .cardTime {
    display: flex
    .timeOne {
      font-size: 0.28rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #0E1429;
      margin-top: 0.1rem;
      width: 3.38rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .timeTwo, .timeThree {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular-, PingFangSC-Regular;
      font-weight: normal;
      color: #606368;
      margin-top: 0.2rem;
    }
    .status {
      position: absolute;
      right: 0.5rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular-, PingFangSC-Regular;
      font-weight: normal;
      margin-top: 0.1rem;
      .statusYellow {
        color: #FAAD14;
        display: flex;
        align-items: center;
      }
      .statusCircleYellow {
        width: 0.12rem;
        height: 0.12rem;
        background: #FDB320;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusGreen {
        color: #52C41A;
        display: flex;
        align-items: center;
      }
      .statusYellow {
        color: #FDB320;
        display: flex;
        align-items: center;
      }
      .statusCircleGreen {
        width: 0.12rem;
        height: 0.12rem;
        background: #52C41A;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusRed {
        color: #F5222D;
        display: flex;
        align-items: center;
      }
      .statusCircleRed {
        width: 0.12rem;
        height: 0.12rem;
        background: #F5222D;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusBlue {
        color: #3F74F6;
        display: flex;
        align-items: center;
      }
      .statusGray {
        color: #a4a6a9;
        display: flex;
        align-items: center;
      }
      .statusGreen {
        color: #10CC99;
        display: flex;
        align-items: center;
      }
      .statusCircleBlue {
        width: 0.14rem;
        height: 0.14rem;
        background: #3F74F6;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusCircleGray {
        width: 0.14rem;
        height: 0.14rem;
        background: #a4a6a9;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusCircleGreen {
        width: 0.14rem;
        height: 0.14rem;
        background: #10CC99;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusCircleYellow {
        width: 0.12rem;
        height: 0.12rem;
        background: #FDB320;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusGray {
        color: rgba(14, 20, 41, 0.4);
        display: flex;
        align-items: center;
      }
      .statusCircleGray {
        width: 0.12rem;
        height: 0.12rem;
        background: rgba(14, 20, 41, 0.4);
        border-radius: 50%;
        margin-right: 0.1rem;
      }
    }
  }
  .pay-bottom {
    height: 0.48rem;
    .pay-btn {
      width: 1.28rem;
      background: #fff;
      border-radius: .1rem;
      border: 1px solid rgba(14, 20, 41, 0.2);
      text-align: center;
      color: #0E1429;
      font-size: .24rem;
      box-sizing: border-box;
      padding: 0.09rem 0;
      position: absolute;
      right: 0.47rem;
    }
  }
}
.nullPic {
  display: flex;
  flex-direction: column;
  align-items: center
  margin-top: 0.2rem;
  font-size: 0.3rem
  img {
    width: 3.6rem;
    height: 3.6rem;
  }
}
.mobileIcon {
  position relative
  .arrow-left {
    position: absolute;
    left: 0.1rem;
    top: -1.85rem;
    width: 0.68rem;
    height: 0.68rem;
    z-index: 1001;
  }
  .arrow-right {
    position: absolute;
    right: 0.1rem;
    top: -1.85rem;
    width: 0.68rem;
    height: 0.68rem;
    z-index: 1001;
  }
}
.swipeImg {
  margin: 0.3rem 0.1rem;
  .cardList {
    width: 48%;
    height: 2.62rem;
    display: flex;
    flex-direction: column;
    justify-content space-between
    align-items: center;
    position: relative;
    background-color: #5ce3c8;
    border-radius 4px;
    overflow hidden
    .top-img {
      width: 100%;
      height: 1.95rem;
      position: relative
      color #fff;
      font-size 11px;
      .mask-content {
        position: absolute;
        bottom 10px;
        left: 10px;
        z-index: 11;
        p {
          margin: 0;
          margin-top: 6px;
        }
      }
      &:after {
        content: '';
        position absolute
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 9;
      }
      .version-logo {
        position: absolute;
        z-index: 11;
        right 0;
        top 0
        width 48px;
        height auto;
      }
    }
    .number {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium-, PingFangSC-Medium;
      font-weight: normal;
      color: #FFFFFF;
      margin: 0.2rem 0.2rem 0.2rem 2.5rem;
      z-index: 1;
    }
    .version {
      font-size: 0.4rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      //margin: 0.1rem 0.3rem 0.51rem;
      z-index: 1;
      max-width: 2.8rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .bckDiv {
      width: 100%;
      background-color: #fff;
      height: 0.7rem;
      text-align: center;
      display flex
      align-items center
      justify-content space-between
      font-size 14px;
      p {
        flex 1
        text-overflow ellipsis
        overflow hidden
        white-space nowrap
        padding 0 10px;
      }
      .ImmediateDetection {
        display inline-block
        width 60px
        font-size: 12px;
        color: #165DFF;
      }
    }
  }
}
.cardList:last-child {
  //margin-right: 5.2rem;
}
>>> .van-swipe-item {
  //margin-right: 0.3rem;
}
>>> .van-swipe__indicators {
  display: none
}
.topStyle {
  margin-top: 0
}
/*#colorList0 {
  background: #3472FF;
}
#colorList1 {
  background: #FDB320;
}
#colorList2 {
  background: #19B6CB;
}
#colorList3 {
  background: #10CC99;
}
#colorList4 {
  background: #FF924E;
}
#colorList5 {
  background: #8249E6;
}
#colorList6 {
  background: #FF6E6E;
}
#colorList7 {
  background: #98C61B;
}
#colorList8 {
  background: #98C61B;
}
#colorList9 {
  background: #98C61B;
}
#colorList10 {
  background: #98C61B;
}
#colorList11 {
  background: #98C61B;
}
#colorList12 {
  background: #98C61B;
}
#colorList13 {
  background: #98C61B;
}
#colorList14 {
  background: #98C61B;
}
#colorList15 {
  background: #98C61B;
}
#colorList16 {
  background: #98C61B;
}*/
>>> .VantDialog {
  font-size: 0.34rem;
  .van-dialog__header {
    padding-top: 0.4rem;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #0E1429;
    font-weight: bold;
  }
  .van-dialog__content {
    padding: 0.32rem 0
    color: #606368;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #E6E8EA;
  }
  .van-dialog__footer {
    font-weight: bold;
    .van-dialog__cancel {
      color: #1D2129;
      height: 1.11rem;
    }
    .van-dialog__confirm {
      color: #165DFF;
      height: 1.11rem;
    }
    .van-button__content {
      border-right: 1px solid #E6E8EA;
    }
  }
}
.shareDiv {
  .shareTitle {
    font-weight: bold;
    font-size: 0.34rem;
    color: #1D2129;
    margin: 0.28rem
  }
  .toptips {
    display: flex !important;
    box-sizing: border-box;
    padding: 10px 15px;
    background: #FFF7E8;
    > i {
      color: rgba(253, 179, 32, 1);
      margin-right: 8px;
      font-size: 16px;
    }
    > div {
      color: #FDB320;
      line-height: 18px;
      font-size: 12px;
    }
  }
  .sharePng {
    img {
      width: 6.9rem
      height: 11rem
    }
  }
}
</style>
