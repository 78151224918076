<template>
    <div>

    </div>
</template>
<script>
import { wechatAuth, autoLogin, getCheckUser } from "@/api/fri";
import { getChannelUnifiedParam, scanRecord } from "@/api/api";
import {goToAuthorizePage} from "@/utils/help";
export default {
    data() {
        return {
            loginCode: "",
            userData: null,
            channelUniqueId: '',
            posterUniqueId: '',
        }
    },

    methods: {
        authorization() {
            let token = localStorage.getItem("token");
            if (token) {
                localStorage.setItem("isMobile", false);
                let params = {
                    channelUniqueId: this.channelUniqueId,
                };
                autoLogin(params).then(async (res) => {
                    if (res.code == 200) {
                        if (res.data.headImgUrl) {
                            localStorage.setItem("headImgUrl", res.data.headImgUrl);
                        }
                        if (res.data.nickname) {
                            localStorage.setItem("nickname", res.data.nickname);
                        }
                        if (res.data.phoneNumber) {
                            localStorage.setItem("phoneNumber", res.data.phoneNumber);
                        }
                        if (res.data.currentOrganizationId) {
                            localStorage.setItem("organizationId", res.data.currentOrganizationId);
                        }
                        if (this.$route.name != 'dataAuthorization') {
                            this.$router.push({
                                path: "/",
                                query: {
                                    channelUniqueId: this.channelUniqueId,
                                },
                            });
                            return
                        } else {
                            const checkRes = await getCheckUser(this.$route.params.uuid)
                            if (checkRes.code == 200) {
                                if (checkRes.data) {

                                } else {
                                    this.$router.push({
                                        path: "/my",
                                        query: {
                                            channelUniqueId: this.channelUniqueId,
                                            code: params.code

                                        },
                                    });
                                    return;
                                }
                            }
                        }



                    }
                });
                return;
            } else {
                // 用户首次进入
                console.log("用户首次进入");
                localStorage.setItem("isMobile", true);
                // 如果存在code,就用code去取一下参数，存在code说明已经从授权页面重定向过来
                this.loginCode = this.$route.query.code;
                if (this.loginCode) {
                    const loading = this.$loading({
                        lock: true,
                        text: "Loading",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    // 请求接口 用code换参数
                    let params = {
                        code: this.$route.query.code,
                        channelUniqueId: this.channelUniqueId
                    };
                    console.log(this.$route)

                    if (this.$route.name == 'boss-watch-report') {
                        if (this.$route.params.token) {
                            localStorage.setItem('token', this.$route.params.token)
                            return;
                        }
                    }
                    wechatAuth(params).then(async (res) => {
                        if (res.data.code == 200) {
                            this.userData = res.data.data;
                            localStorage.setItem("headImgUrl", this.userData.headImgUrl);
                            localStorage.setItem("nickname", this.userData.nickname);
                            localStorage.setItem("unionId", this.userData.unionId);
                            localStorage.setItem("organizationId", this.userData.currentOrganizationId);

                            //
                            // 通过这边的参数判断需不需要填写手机号
                            if (this.userData.phoneNumber == null) {
                                localStorage.setItem("isMobile", true);
                            } else {
                                // 存下token
                                if (this.$route.name == 'dataAuthorization') {
                                    const checkRes = await getCheckUser(this.$route.params.uuid)
                                    if (checkRes.code == 200) {
                                        if (checkRes.data) {

                                        } else {
                                            this.$router.push({
                                                path: "/my",
                                                query: {
                                                    channelUniqueId: params.channelUniqueId,
                                                    code: params.code

                                                },
                                            });
                                            return;
                                        }
                                    }
                                }



                                localStorage.setItem('isMobile', false)

                                localStorage.setItem("token", res.headers["authorization"]);
                                localStorage.setItem("phoneNumber", this.userData.phoneNumber);
                            }
                        } else {
                          goToAuthorizePage({
                            unifiedParamId: localStorage.getItem("unifiedParamId"),

                          })
                        }
                        loading.close();
                    });
                } else {
                    localStorage.setItem("isMobile", false);
                  goToAuthorizePage({
                            unifiedParamId: localStorage.getItem("unifiedParamId"),

                    });
                }
            }
        },
    },
    async created() {

        //获取H5扫码记录
        if (this.$route.query.unifiedParamId) {
            localStorage.setItem("unifiedParamId", this.$route.query.unifiedParamId);

            const res = await getChannelUnifiedParam(this.$route.query.unifiedParamId)
            if (res.code == 200) {
                localStorage.setItem("channelUniqueId", res.data.channelUniqueId);
                this.channelUniqueId = res.data.channelUniqueId
                this.posterUniqueId = res.data.posterUniqueId
                localStorage.setItem("posterUniqueId", res.data.posterUniqueId ? res.data.posterUniqueId : '');
                if (res.data.posterUniqueId) {
                    const res2 = await scanRecord(this.$route.query.unifiedParamId)
                    if (res2.code == 200) {
                        await this.authorization()
                    }
                } else {
                    await this.authorization()
                }

            }
        }




    },
}
</script>
