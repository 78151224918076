<script>
export default {
name: "WxJump",
  mounted() {
    console.log("dsadsa")
    const code = this.$route.query.code;
    const to = this.$route.query.to;
    const state = this.$route.query.state;
    if (code) {
      window.location = `${to}?code=${code}&state=${state}`;
    }
  }
}
</script>

<template>

</template>

<style scoped lang="stylus">

</style>
