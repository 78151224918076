<template>
  <div class="order-container">
<!--    <div><button @click="handleTestClick">test1</button></div>-->
    <payItem class="good" :type="order" :goodsCount="goodsCount" :item="payItemGoodInfo"
             @changeNum="changeNum"></payItem>
    <goodInfos class="info" :goodInfo="goodInfo.productConfigJson"
               style="display: none"></goodInfos>
    <div class="submit-account" style="display: none" v-if="goodInfo.templateId != 6">
      <div class="title">系统账号</div>
      <div class="pay-bottom-text" v-if="goodInfo.prompt">
        <i class="el-icon-warning-outline"></i>
        <div>
          {{ goodInfo.prompt }}
        </div>
      </div>
      <div class="item">
        <div>企业名称</div>
        <div>
          <el-input :disabled="selectCompany" v-model.trim="companyName"
                    placeholder="请输入公司名称"></el-input>
        </div>
      </div>
      <div class="item item-botom">
        <div>手机号</div>
        <div>
          <el-input v-model.trim="mobile" placeholder="请输入手机号"></el-input>
        </div>
        <i class="el-icon-mobile-phone item-phone" @click="showPhoneList"></i>
        <i v-if="mobile" class="el-icon-circle-close item-close" @click="cancelPhoneList"></i>
      </div>
    </div>
    <!--商品属性-->
    <div class="submit-account">
      <div v-for="(item,index) in goodInfo.goodsAttrs" :key="item.groupId">
        <div class="title">{{ item.groupName }}</div>
        <OrderRadio @update:data="handleOrderRadioUpdate(index,$event)" :data="item.selectedAttrVal" :options="item.goodsAttrGroups" />
      </div>
      <!-- <div class="title">版本</div>
       <orderRadio :data.sync="version" />
       <div class="title mt-10">收费模式</div>
       <orderRadio :data.sync="mode" />-->
    </div>
    <!--属性响应的权限图片-->
    <img class="haibao" :src="equityImages" alt="">
    <!--基本信息-->
    <div class="submit-account">
      <div class="pay-bottom-text" v-if="goodInfo.prompt">
        <i class="el-icon-warning-outline"></i>
        <div>
          {{ goodInfo.prompt }}
        </div>
      </div>
      <div class="itemDetail">
        <div>服务商名称</div>
        {{ goodInfo.channelName }}
      </div>
      <div class="itemDetail">
        <div>服务热线</div>
        {{ goodInfo.channelPhone || "-" }}
      </div>
      <div class="itemDetail">
        <div>购买须知</div>
        本产品不支持退款
      </div>
      <!--      <div class="divider"></div>-->
      <!-- <div class="itemDetail">
         <div style="font-weight: bold">应付金额</div>
         <div style="color: #165DFF;font-size: 0.28rem;font-weight: bold">¥
           {{ formatToDecimal(Number(goodsCount) * Number(goodInfo.currentPrice)) }}
         </div>
       </div>-->
    </div>
    <div class="footer-toolbar" v-if="goodInfo">
      <div
        style="font-size: .28rem;display: flex;align-items: center;line-height: 1.5;margin-bottom: .32rem">
        <van-checkbox v-model="agreeCheck" shape="square" style="flex-shrink: 0;">已阅读并同意签署
        </van-checkbox>
        <button class="btn btn-link" style="padding: 0;color: #165DFF;height: auto"
                @click="isShowAgreement=true">《智能财税服务平台服务协议》
        </button>
      </div>
      <button class="btn btn-primary" @click="submit" style="font-size: .32rem;">
        立即支付 ￥{{ formatToDecimal(Number(goodsCount) * Number(currentGoodsSkuInfo?.price||0)) }}
      </button>
    </div>
    <!-- <el-drawer title="已购买手机号" :visible.sync="drawer" :direction="direction">
      <div class="isUserName">
        <div v-for="(item, index) in orderCompanyList" class="items" @click="selectPhone(index)">
          {{ item.username }}({{ item.companyName }})
        </div>
      </div>
    </el-drawer> -->
    <van-popup v-model="isShowAgreement" position="bottom" closeable>
      <Agreement @agree="handleAgree"></Agreement>
    </van-popup>
  </div>
</template>
<script>
import payItem from "../components/pay-item2.vue";
import goodInfos from "../components/good-info.vue";
import OrderRadio from "./components/OrderRadio.vue";
import { Dialog, Toast } from 'vant';
import {
  unifiedOrderWx, wxCreateOrder
} from "@/api/wx";
import Agreement from "@/pages/components/Agreement.vue";
import { formatToDecimal } from "@/utils/format";
import { createOrder } from "@/api/api";
import {remoteEmitUni} from "@/packages/RemoteService/remoteExpose";
import {waitUntilUniAppJSBridgeReady} from "@/utils/uniapp";
import {getGoodsDetail} from "@/api/wx";
import {cloneDeep} from "lodash";
import wx from "weixin-js-sdk";

export default {
  metaInfo: {
    title: "填写订单信息",
  },
  components: {
    Agreement,
    payItem,
    goodInfos,
    OrderRadio
  },

  data () {
    return {
      /*attrs:{

      },*/
      isShowAgreement: false,
      agreeCheck: false,
      order: 2,
      companyName: "",
      mobile: "",
      pwd: "",
      pwd2: "",
      goodInfo: {},
      payInfo: {},
      item: this.$route.query.item ? JSON.parse(this.$route.query.item) : {},
      goodsCount: 1,
      // orderCompanyList: [],
      orderCompany: "",
      drawer: false,
      direction: "btt",
      selectCompany: false,
      channelUniqueId: localStorage.getItem("channelUniqueId"),
      base: process.env.VUE_APP_API_ROOT,
    };
  },
  computed: {
    payItemGoodInfo(){
      let ret = {
        showImageUrl:'',
        attrs:'',
        productName:'',
        currentPrice:'',
        salesUnitPrice:'',
        bundlingNum:0
      }
      if(this.currentGoodsSkuInfo){
        ret = {
          ...this.goodInfo,
          attrs:'',
          productName:this.goodInfo.goodsName,
          currentPrice:this.currentGoodsSkuInfo.price,
          salesUnitPrice:'',
          bundlingNum:null
        }
      }

      return ret
    },
    currentGoodsSkuInfo(){

      if (!this.goodInfo?.goodsSkuInfo) {
        return null
      }
      /**
       *
       * @type {{groupKey: string, attrKey:string}[]}
       */
      const selectedSku = this.goodInfo?.goodsAttrs.map(item=>{
        return {
          groupKey: item.groupKey,
          attrKey: item.selectedAttrVal
        }
      })
      const ret = this.goodInfo.goodsSkuInfo.find(aGoodsSkuInfo => {
        return aGoodsSkuInfo.skuAttr.every((skuAttr, index) => {
          console.log(skuAttr,selectedSku[index])
          const ret2 = skuAttr.attrKey === selectedSku[index]?.attrKey
          return ret2
        })
      })
      return ret
    },
    equityImages () {
      const imgList = this.goodInfo?.equityImages ?? null
      if (imgList) {
        const url = imgList.find(i => i.image === 'h5')?.type ?? ''
        return url
      } else {
        return ''
      }

    }
  },
  async mounted () {
    //await waitUntilUniAppJSBridgeReady()
    await this.getGoodInfo();
  },
  methods: {
    getLoginInfo(){
      // 获取用户登录
      return new Promise((resolve,reject)=>{
        wx.login({
          success (res) {
            if (res.code) {
              resolve(res)
            } else {
              reject()
              console.log('登录失败！' + res.errMsg)
            }
          }
        })
      })
    },
    handleOrderRadioUpdate(index,e){
      const goodInfo = cloneDeep(this.goodInfo)
      goodInfo.goodsAttrs[index].selectedAttrVal = e
      this.goodInfo = goodInfo
    },
    handleTestClick(){
      remoteEmitUni("hello", {value:'world'})
    },
    formatToDecimal,
    handleAgree () {``
      this.agreeCheck = true
      this.isShowAgreement = false
    },
    selectPhone (act) {
      // this.companyName = this.orderCompanyList[act].companyName;
      // this.mobile = this.orderCompanyList[act].username;
      this.drawer = false;
      this.selectCompany = true;
    },
    showPhoneList () {
      this.drawer = true;
    },
    changeNum (val) {
      this.goodsCount = val;
    },

    async submit () {
      if (!this.agreeCheck) {
        Toast('请先勾选协议');
        return
      } else {


        //下单
        /** @type {wx.createOrderRequestParams} */
        const params = {
          goodsCount: this.goodsCount,
          skuId: this.currentGoodsSkuInfo.skuId,
          goodsId: this.currentGoodsSkuInfo.goodsId,
          orderSource: 'AI-H5'
        }
        const res = await wxCreateOrder(params)
        if (res.code == 200) {
          wx.miniProgram.navigateTo({
            url: `/pages/pay/Pay?orderNo=${res.data.orderNo}`
          })
          // this.$router.push({
          //   name: 'wx-pay',
          //   params: {
          //     orderNo: res.data.orderNo
          //   }
          // })
        }

       // const res = await this.getLoginInfo()
      //   const res = await unifiedOrderWx({
      //     goodsCount: this.goodsCount,
      //     skuId: this.currentGoodsSkuInfo.skuId,
      //     goodsId: this.currentGoodsSkuInfo.goodsId,
      //     orderSource: '',
      //     openId
      // });
      //   //this.loading.close();
      //   if (res.code == 200) {
      //     this.payInfo = res.data;
      //     this.StartWeiXinPay(this.payInfo);
      //     if (res.code != 200 && res.code != 5001) {
      //       this.$message({
      //         message: res.data,
      //         type: "warning",
      //       });
      //     }
      //   }





      }

      // if (!this.companyName || !this.mobile) {
      //   this.$message({
      //     message: "请输入完整信息！",
      //     type: "warning",
      //   });
      //   return;
      // }


      // let params = {
      //   goodsId: this.goodInfo.policyId,
      //   phoneNumber: this.mobile,
      // };
      //支付订单前校验系统登录手机号
      // let checkStatus = await checkPhoneNumber(params);
      // if (checkStatus.code != 200) {
      //   this.loading.close();
      // }
      // if (checkStatus.data) {
    },
    async getGoodInfo () {
      const res = await getGoodsDetail({
        goodsId: this.$route.params.goodsId
      })
      this.goodInfo = res.data
       this.goodInfo.goodsAttrs = this.goodInfo.goodsAttrs.map(item=>{
        item.selectedAttrVal = item.goodsAttrGroups[0]?.attrKey
        return item
      })
      console.log("dsadsa",res)
      // let params = {
      //   policyId: this.$route.query.id,
      //   channelUniqueId: this.channelUniqueId,
      //   posterUniqueId: localStorage.getItem("posterUniqueId"),
      // };
      // getPayInfo(params).then((res) => {
      //   if (res.code == 200) {
      //     this.goodInfo = res.data;
      //   }
      // });
    },
    StartWeiXinPay (pay_params) {


      // {orderNo: "D2025022800000009441", prepayId: "wx28200243618339a1a7674a60dc00f30001", timeStamp: "1740744163", nonceStr: "qNNodrvkBQ2aKUqV", sign: "14CEEBD7A6B2C04D0A7194B4B61DB8B4"}

      console.log(pay_params);
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          alert(1);
          document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady(pay_params), false);
        } else if (document.attachEvent) {
          alert(2);
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady(pay_params));
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady(pay_params));
        }
      } else {
        this.onBridgeReady(pay_params);
      }
    },

    onBridgeReady (pay_params) {
      let id = this.goodInfo.policyId;

      var that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: "wx9c1cb1b0003d9f85", //公众号ID，由商户传入
          timeStamp: pay_params.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: pay_params.nonceStr, //随机串
          package: "prepay_id=" + pay_params.prepayId,
          signType: "MD5", //微信签名方式：
          paySign: pay_params.sign, //微信签名
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // that.$router.push({
            //   path: "/detail",
            //   query: {
            //     orderNo: pay_params.orderNo,
            //   },
            // });
            //支付成功跳转首页

            that.$router.push({
              path: "/paymentSuccessful",
              query: {
                id: id,
              },
            });
            // location.href = this.base + "/paymentSuccessful" + "?id=" + this.goodInfo.policyId;
          } else if (res.errMsg == "get_brand_wcpay_request:ok") {
            // location.href = this.base + "/paymentSuccessful" + "?id=" + this.goodInfo.policyId;
            that.$router.push({
              path: "/paymentSuccessful",
              query: {
                id: id,
              },
            });
          } else {
            alert("支付失败");
          }
        }
      );
    },
    // getCompanyList() {
    // 获取已经购买过的手机号公司名称
    // getBuyLoginInfoList(this.goodInfo.policyId).then((res) => {
    //   console.log(res);
    //   this.orderCompanyList = res.data;
    // });
    // },
    // orderChange() {
    // this.companyName = this.orderCompanyList[this.orderCompany].companyName;
    // this.mobile = this.orderCompanyList[this.orderCompany].username;
    // },
    cancelPhoneList () {
      this.mobile = "";
      this.companyName = "";
      this.selectCompany = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.mt-10 {
  margin-top: 10px;
}
.good {
  margin: 0.2rem 0 0.2rem 0.2rem;
}
.info {
  margin-left: 0.2rem;
}
.haibao {
  width: 7.1rem;
  height: auto;
  margin: 0.2rem 0 0 0.2rem;
  border-radius: 0.2rem;
}
.submit-account {
  width: 7.1rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  opacity: 1;
  font-size: 0.28rem;
  margin: 0.2rem 0 0 0.2rem;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
  padding-right: 0.3rem;
  padding-bottom: 0.3rem;
  .title {
    font-size: 0.32rem;
    line-height: 0.46rem;
    padding: 0.3rem 0;
    padding-top: 0;
    font-weight: bold;
  }
  .item, .itemDetail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1rem;
    border-bottom: 1px solid rgba(14, 20, 41, 0.1);
    box-sizing: border-box;
    .item-phone {
      position: absolute;
      right: -8px;
      padding: 0 6px;
    }
    .item-close {
      position: absolute;
      right: 12px;
      padding: 0 6px;
      color: #C0C4CC;
    }
    >>> .el-input {
      width: 3.4rem;
    }
    >>> .el-input__inner {
      border: none;
      padding-right: 0;
    }
    >>> .el-input__inner::placeholder {
      font-size: 0.3rem;
      text-align: right;
      font-size: 0.28rem;
    }
    >>> .el-input--suffix .el-input__inner {
      padding-right: 0;
    }
    >>> .el-input.is-disabled .el-input__inner {
      background: #fff;
      color: #606266;
    }
  }
  .itemDetail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 0.4rem;
    margin-bottom 0.3rem
    box-sizing: border-box;
    border-bottom: 0px solid #fff;
  }
  .itemDetail:last-child {
    margin-bottom 0
  }
  .item-botom {
    border-bottom: none;
    >>> .el-input__inner {
      padding-right: 18px;
    }
  }
}
.btn {
  //width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  //margin-bottom: 0.68rem;
  background: #165DFF;
  border-radius: 0.1rem;
  opacity: 1;
  font-size: 0.34rem;
  text-align: center;
  color: #fff;
}
.btn-c {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  margin-top: 0.4rem;
}
.order-container {
  padding-bottom: 2.2rem;
}
.pay-bottom-text {
  display: flex;
  align-items: baseline;
  width: 6.5rem;
  background: rgba(253, 179, 32, 0.08);
  border-radius: 0.1rem;
  opacity: 1;
  // margin-top: 0.3rem;
  color: #FDB320;
  font-size: 0.24rem;
  padding: 0.2rem 0.3rem 0.2rem 0.2rem;
  box-sizing: border-box;
  line-height: 1.7;
  margin-bottom: 0.2rem;
  .el-icon-warning-outline {
    margin-right: 0.1rem;
  }
}
.isUserName {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #0E1429;
  padding: 0 30px;
  .items {
    padding: 0.12rem 0;
    // border-bottom: 1px solid #0E1429;
  }
}
.divider {
  border-bottom: 1px solid rgba(14, 20, 41, .1);
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
</style>
