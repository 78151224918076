<script>
import {getUserInfoApi} from "@/api/api";
import {getPageOrderLogApi} from "@/api/wx";
import PayItem3 from "@/pages/components/PayItem3.vue";
export default {
  metaInfo: {
    title: "我的订单",
  },
  components:{
    PayItem3
  },
  async mounted () {
    await this.loadUserInfoInfo()
    this.init();
    this.getPageLength();
  },
  data () {
    return {
      userInfo:{},
      statusList: new Map([[0, '生成中'], [1, '生成中'], [2, '已生成'], [-2, '已删除'], [3, '未授权']]),
      showFixBottom: true,
      active: 3,
      payStatusType: localStorage.getItem("payStatusType") || '',
      payList: null,
      payListSuccess: 0,
      payListWaitForPay: 0,
      payListCancel: 0,
      payListTotal: 0,
    }
  },
  methods:{
    async loadUserInfoInfo() {
      const res = await getUserInfoApi()
      this.userInfo = res.data
    },
    init () {
      // // 获取可检测版本和次数
      // const currentOrganizationId = this.userInfo.curOrgId
      // getCheckVersionDataApi({
      //   organizationId: currentOrganizationId,
      //   displayPlatform: "1",
      // }).then((res) => {
      //   if (res.code == 200) {
      //     this.bankData = res.data;
      //     if (res.data.length == "0" || res.data.length == "1" || res.data.length == "2") {
      //       this.showArrow = false;
      //     }
      //   }
      // });
      //
      // let params = {
      //   companyName: "",
      //   endTime: "",
      //   organizationId: currentOrganizationId ? currentOrganizationId : "",
      //   pageNum: 1,
      //   pageSize: 10000,
      //   status: "",
      //   startTime: "",
      //   // channelUniqueId: channelUniqueId ? channelUniqueId : "",
      // };
      // modelCheckGetPageApi(params).then((res) => {
      //   this.dataPage = res.data.list;
      // });
      this.getPage();
    },
    payStatus (val) {
      this.payStatusType = val === 0 ? '' : val;
      localStorage.setItem("payStatusType", this.payStatusType);
      this.getPage();
    },
    getPage () {
      let channelUniqueId = localStorage.getItem("channelUniqueId");

      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        channelName: "",
        endDate: "",
        orderNo: "",
        orderStatus: this.payStatusType, // 状态(-1 订单取消;0=订单初始状态 1=待支付 2=已支付)
        pageNum: 0,
        pageSize: 10000,
        phonenumber: "",
        startDate: "",
        channelUniqueId,
      };
      // 获取渠道订单信息表分页列表
      getPageOrderLogApi(params).then((res) => {
        this.loading.close();
        if (res.code == 200) {
          this.payList = res.data.list.filter(item => {return !item.channelFlag}).map(item=>{
            return {
              orderNo:item.orderNo,
              showImageUrl:item.channelPolicyConfigInfoVOS[0]?.showImageUrl,
              attrs:item.channelPolicyConfigInfoVOS[0]?.attrs,
              price:item.channelPolicyConfigInfoVOS[0]?.salesUnitPrice||item.channelPolicyConfigInfoVOS[0]?.productUnitPrice,
              goodsCount:item.channelPolicyConfigInfoVOS[0]?.num,
              productName:item.channelPolicyConfigInfoVOS[0]?.productName||item.channelPolicyConfigInfoVOS[0]?.goodsName,
              totalAmount:item.settlementAmount,
              createTime:item.createTime,
              orderStatus:item.orderStatus
            }
          });
          console.log("payList",this.list)
        }
      });
    },
    getPageLength () {
      let channelUniqueId = localStorage.getItem("channelUniqueId");

      let params = {
        orderStatus: "", // 状态(-1 订单取消;0=订单初始状态 1=待支付 2=已支付)
        pageNum: 0,
        pageSize: 10000,
        channelUniqueId,
      };
      // 获取渠道订单信息表分页列表
      getPageOrderLogApi(params).then((res) => {
        console.log(res)
        if (res.code == 200) {
          const list =  res.data.list.filter(item => {return !item.channelFlag});
          this.payListSuccess = list.filter((item) => item.orderStatus == 2);
          this.payListCancel = list.filter((item) => item.orderStatus == -1);
          this.payListWaitForPay = list.filter((item) => item.orderStatus == 1);
          this.payListTotal = list;
        }
      });
    },
    toIndex () {
      this.$router.back();
    },
  }
}
</script>

<template>
  <div>
    <div class="card-item card-item-pay" >
      <div class="pay-title">
        <div :class="{ payActive: payStatusType == 0 }" @click="payStatus(0)">
          全部({{ payListTotal.length }})
        </div>
        <div :class="{ payActive: payStatusType == 1 }" @click="payStatus(1)">
          待支付({{ payListWaitForPay.length }})
        </div>
        <div :class="{ payActive: payStatusType == 2 }" @click="payStatus(2)">
          已完成({{ payListSuccess.length }})
        </div>
        <div :class="{ payActive: payStatusType == -1 }" @click="payStatus(-1)">
          已取消({{ payListCancel.length }})
        </div>
      </div>
      <div v-if="payList && payList.length > 0">
        <template  >
          <PayItem3 class="pay-item" from="wx" :item="item" :key="index" v-for="(item,index) in payList"></PayItem3>
        </template>
      </div>
      <div v-else class="nodata">
        <img src="@/assets/no-data.png" alt="" />
        <div class="nodata-title">还没有提交过订单信息哦～</div>
<!--        <div class="nodataBtn" @click="toIndex">立即购买</div>-->
      </div>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.card-item {
  margin: 0rem auto 0;
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 3.6rem;
      margin-top: 1.2rem;
      margin-bottom: 0.2rem;
    }
    .nodata-title {
      line-height: 0.42rem;
      color: #0E1429;
      font-size: 0.3rem;
    }
    .nodataBtn {
      width: 4rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: #165DFF;
      border-radius: 0.1rem;
      opacity: 1;
      color: #fff;
      text-align: center;
      margin-top: 0.8rem;
    }
  }
  .try-input {
    margin-left: 0.2rem;
  }
  .pay-title {
    display: flex;
    justify-content: space-around;
    //padding: 0.16rem 0 0.28rem 0;
    color: rgba(14, 20, 41, 1);
    font-size: 0.28rem;
    font-weight: 400;
    align-items: center;
    margin-bottom 0.28rem
    padding-top 0.36rem;
    .payActive {
      color: rgba(63, 116, 246, 1);
      font-weight: bold;
      font-size: 0.32rem;
    }
  }
}
  .pay{
    margin 0.2rem auto
  }
.qr-code {
  background: url("../../assets/qrCode.png") no-repeat center 0px;
  background-size: cover;
  width: 6.9rem;
  height: 4.1rem;
  margin-top: 1rem;
  margin-left: 0.3rem;
  font-size: 0.26rem;
  text-align: center;
  padding-top: 0.5rem;
  box-sizing: border-box;
  .wx-code {
    width: 1.8rem;
    height: 1.8rem;
    background: #fff;
    margin: 0 auto;
    margin-bottom: 0.36rem;
    img {
      width: 100%;
    }
  }
  .phone {
    color: #0E1429;
    margin-top: 0.2rem;
    line-height: 0.38rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-size: 0.26rem;
  }
}

.detection {
  //margin: 0.4rem 0.3rem 0.3rem;
  font-size: 0.32rem;
  font-weight: bold;
  color: #0E1429;
  padding-left: 0.3rem
  line-height: 48px;
}
>>> .el-radio-button__inner {
  width: 1.22rem;
  height: 0.64rem;
  font-size: 0.24rem;
  font-weight: bold;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
>>> .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #E0E9FE;
  border-radius: 10px;
  border: none;
  font-size: 0.24rem;
  font-weight: bold;
  color: #3F74F6;
  box-shadow: none;
}
>>> .el-radio-group label {
  margin-right: 0.2rem;
}
>>> .el-radio-button:first-child .el-radio-button__inner {
  border-left: none;
  border-radius: 10px;
}
>>> .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 10px;
}
.detectionCard {
  //width: 7.10rem;
  //height: 2.68rem;
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  margin: 0.3rem 0.2rem 0
  padding: 0.2rem;
  box-sizing: border-box;
  .cardTime {
    display: flex
    .timeOne {
      font-size: 0.28rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #0E1429;
      margin-top: 0.1rem;
      width: 3.38rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .timeTwo, .timeThree {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular-, PingFangSC-Regular;
      font-weight: normal;
      color: #606368;
      margin-top: 0.2rem;
    }
    .status {
      position: absolute;
      right: 0.5rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular-, PingFangSC-Regular;
      font-weight: normal;
      margin-top: 0.1rem;
      .statusYellow {
        color: #FAAD14;
        display: flex;
        align-items: center;
      }
      .statusCircleYellow {
        width: 0.12rem;
        height: 0.12rem;
        background: #FDB320;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusGreen {
        color: #52C41A;
        display: flex;
        align-items: center;
      }
      .statusYellow {
        color: #FDB320;
        display: flex;
        align-items: center;
      }
      .statusCircleGreen {
        width: 0.12rem;
        height: 0.12rem;
        background: #52C41A;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusRed {
        color: #F5222D;
        display: flex;
        align-items: center;
      }
      .statusCircleRed {
        width: 0.12rem;
        height: 0.12rem;
        background: #F5222D;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusBlue {
        color: #3F74F6;
        display: flex;
        align-items: center;
      }
      .statusGray {
        color: #a4a6a9;
        display: flex;
        align-items: center;
      }
      .statusGreen {
        color: #10CC99;
        display: flex;
        align-items: center;
      }
      .statusCircleBlue {
        width: 0.14rem;
        height: 0.14rem;
        background: #3F74F6;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusCircleGray {
        width: 0.14rem;
        height: 0.14rem;
        background: #a4a6a9;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusCircleGreen {
        width: 0.14rem;
        height: 0.14rem;
        background: #10CC99;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusCircleYellow {
        width: 0.12rem;
        height: 0.12rem;
        background: #FDB320;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .statusGray {
        color: rgba(14, 20, 41, 0.4);
        display: flex;
        align-items: center;
      }
      .statusCircleGray {
        width: 0.12rem;
        height: 0.12rem;
        background: rgba(14, 20, 41, 0.4);
        border-radius: 50%;
        margin-right: 0.1rem;
      }
    }
  }
  .pay-bottom {
    height: 0.48rem;
    .pay-btn {
      width: 1.28rem;
      background: #fff;
      border-radius: .1rem;
      border: 1px solid rgba(14, 20, 41, 0.2);
      text-align: center;
      color: #0E1429;
      font-size: .24rem;
      box-sizing: border-box;
      padding: 0.09rem 0;
      position: absolute;
      right: 0.47rem;
    }
  }
}
.nullPic {
  display: flex;
  flex-direction: column;
  align-items: center
  margin-top: 0.2rem;
  font-size: 0.3rem
  img {
    width: 3.6rem;
    height: 3.6rem;
  }
}
</style>
