import {get, post} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;


/**
 * @typedef {Object} GoodsAttrGroup
 * @property {number} groupId - 属性分组ID
 * @property {string} groupName - 分组名
 * @property {string} groupKey - 分组key
 * @property {string[]} attrKey - 属性key
 * @property {string[]} attrVal - 属性值
 * @property {number[]} attrId - 属性ID
 */
/**
 * @typedef {Object} GoodsSkuInfo
 * @property {GoodsAttrGroup[]} skuAttr - sku属性
 * @property {number} price - 价格
 */

/**
 * @typedef {Object} GoodsAttr
 * @property {number} groupId - 属性分组ID
 * @property {string} groupName - 分组名
 * @property {string} groupKey - 分组key
 * @property {GoodsAttrGroup[]} goodsAttrGroups - 属性ID
 *
 */
/**
 * @typedef {Object} 基础商品
 * @property {number} goodsId - 商品ID
 * @property {string} goodsName - 商品名称
 * @property {string} appKey - appKey
 * @property {number} templateId - 模板Id
 * @property {string} templateName - 模板名称
 * @property {number} goodsSort - 商品排序
 * @property {string} remark - 商品备注
 * @property {GoodsAttr[]} goodsAttrs - 商品勾选的销售属性
 * @property {GoodsSkuInfo[]} goodsSkuInfo - 商品sku
 * @property {string} showImageUrl - 商品主图
 * @property {Array} images - 图文详情
 * @property {boolean} draftFlag - 是否保存草稿 TRUE  草稿 false 不是草稿
 */


/**
 * 获取商品详情
 * @param {{goodsId:string,skuId:string}} param
 * @returns {Promise<Res<基础商品>>}
 */
export const getGoodsDetail = (param) => {
  return post(`${base}/shuian-home/goods/getGoodsDetail`,param)
    .then(res => res.data);
};

/**
 * @typedef {Object} UnifiedOrderParams
 * @property {number} goodsCount
 * @property {number} skuId
 * @property {number} goodsId
 * @property {string} orderSource
 */

/**
 * 发起预支付
 * @param {UnifiedOrderParams} params
 * @returns {Promise<unknown>}
 */
export const unifiedOrderWx = (params) => {
  return post(
    `${base}/shuian-home/pay/unifiedOrder`, params)
    .then(res => res.data);
};


/**
 * @typedef {Object} ShareInfo
 * @property {string} date
 * @property {number} num
 */
/**
 * 获取分享信息
 * @returns {Promise<Res<ShareInfo[]>>}
 */
export const getShareInfo = () => {
  return get(`${base}/shuian-home/share/getShareInfo`).then(res => res.data);
}

/**
 * @typedef {Object} PageOrderLogParam
 * @property {string} orderNo
 * @property {string} channelName
 * @property {number} orderStatus
 * @property {string} goodsName
 * @property {number} pageNum
 * @property {number} pageSize
 * @property {string} endDate
 * @property {string} startDate
 */
/**
 *
 * @param params
 * @returns {Promise<unknown>}
 */

//获取订单信息分页列表-客户
export const getPageOrderLogApi = (params) => {
  return post(`${ base }/shuian-home/order/customer/getPage`, params).then(res => res.data);
}

/**
 * @typedef {Object} GetOrderDetailInfoParam
 * @property {string} orderNo
 */
/**
 * 获取订单信息详情
 * @param {GetOrderDetailInfoParam} params
 * @returns {Promise<unknown>}
 */
export const getOrderDetailInfo = (params) => {
  return get(`${ base }/shuian-home/order/getOrderDetailInfo`, params).then(res => res.data);
}


/**
 * @template T
 * @typedef api.Responce
 * @property {string} message
 * @property {number} code
 * @property {T} data
 */



/**
 * @typedef {Object} wx.createOrderRequestParams
 * @property {number} goodsCount
 * @property {number} skuId
 * @property {number} goodsId
 * @property {string} orderSource
 */
/**
 *
 * @param {wx.createOrderRequestParams} params
 * @returns {Promise<Response<{orderNo:string}>>}
 */
export function wxCreateOrder(params){
  return post(
    `${base}/shuian-home/pay/createOrder`, params)
    .then(res => res.data);
}

/**
 * 取消待支付订单
 * @param {{orderNo:string}} params
 * @returns {Promise<unknown>}
 */
export function wxCancelOrder(params){
  return get(
    `${base}/shuian-home/pay/cancelOrder`, params)
    .then(res => res.data);
}
