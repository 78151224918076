import axios from "axios";
import router from "@/router";

import {
  Message
} from 'element-ui'
import {goToAuthorizePage} from "@/utils/help";
import wx from "weixin-js-sdk";

axios.defaults.timeout = 1200000;


// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    if (response.data && response.data.code == "5000") {
      goToAuthorizePage({
        unifiedParamId: localStorage.getItem("unifiedParamId")
      })
      localStorage.clear();
      sessionStorage.clear();
      return Promise.reject(response);
    } else if (response.data.code != "200" && response.data.code != "40163"&& response.data.code != 40163) {
      if(response.data.code==5201){

      }else if (response.data.code == 5202) {
        // wx.miniProgram.navigateTo({
        //   url: "/pages/Login/index",
        // });
        router.push({
          name: "noPoster",
        });
      }else{
        if (response.data.message) {
          Message.warning({
            message: response.data.message
          });
        }
      }

      return Promise.resolve(response);
    } else {
      return Promise.resolve(response);
    }
  } else {
    return Promise.reject(response);
  }
});

axios.interceptors.request.use(
  config => {
    let token = window.token||localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);



/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, isAutoWaring = true) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        if (isAutoWaring && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({
            message: res.data.msgInfo
          });
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, isAutoWaring = true, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(res => {
        if (isAutoWaring && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({
            message: res.data.msgInfo
          });
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default axios;
