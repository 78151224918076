<script>
import payItem from "@/pages/components/pay-item.vue";
import { modelCheckGetPageApi } from "@/api/poster";
import {getUserInfoApi} from "@/api/api";
import { getAttrVal, getCornerPic } from '@/utils/help.js'
import ATestLog from "@/pages/wx/components/ATestLog.vue";
export default {
  name: "TestLog",
  components: {ATestLog},
  metaInfo: {
    title: "我的授权",
  },
  data(){
    return {
      dataPage:[],
      userInfo:{},
      statusList: new Map([[0, '生成中'], [1, '生成中'], [2, '已生成'], [-2, '已删除'], [3, '未授权']]),
    }
  },

  async mounted() {
    await this.loadUserInfoInfo()
    await this.loadList()
  },
  computed: {},
  methods:{
    async loadUserInfoInfo() {
      const res = await getUserInfoApi()
      this.userInfo = res.data
    },
    async loadList(){
      let params = {
        companyName: "",
        endTime: "",
        organizationId: this.userInfo.curOrgId,
        pageNum: 1,
        pageSize: 10000,
        status: "",
        startTime: "",
        // channelUniqueId: channelUniqueId ? channelUniqueId : "",
      };
      const res = await modelCheckGetPageApi(params)
      this.dataPage= res.data.list
    }
  }
}
</script>

<template>
  <div>
    <div class="nullPic" v-if="dataPage == ''">
      <img src="@/assets/暂无数据.png" alt="" />
      您还没有发起过风险检测哦～
    </div>
    <div
      v-else
      class="detectionCard"
      v-for="(item, index) in dataPage"
      :key="index"
      :class="index == 0 ? 'topStyle' : ''"
    >
      <ATestLog :item="item"></ATestLog>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.detectionCard {
  //width: 7.10rem;
  //height: 2.68rem;
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  margin: 0.3rem 0.2rem 0
  padding: 0.2rem;
  box-sizing: border-box;

}
.nullPic {
  display: flex;
  flex-direction: column;
  align-items: center
  margin-top: 3.2rem;
  font-size: 0.3rem
  img {
    width: 3.6rem;
    height: 3.6rem;
  }
}
</style>
