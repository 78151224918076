import {getUrlQuery} from "./helper";
import wx from "weixin-js-sdk";

const query = getUrlQuery();
const iframeId = query._iframe;
/**
 *
 * @type {Object.<string,(...any) => void>}
 */
export const remoteExposesStore = {};

/**
 * 定义远程暴露的方法
 * @param { Object.<string,(...any) => void> } params
 */
export function defineRemoteExpose(params) {
  for (const key in params) {
    const theExposeKey = `${key}`
    if (remoteExposesStore[theExposeKey] != undefined) {
      console.error(`defineRemoteExpose中已经存在${key}方法,请检查重复`)
    }
    remoteExposesStore[theExposeKey] = params[key]
  }
}


export function remoteEmitUni(name, params) {
  wx.miniProgram.postMessage({
    data: {
      action: 'message'
    }
  });
}
