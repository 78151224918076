import {remoteExposesStore} from "./remoteExpose";

function invoke(data) {
  const method = remoteExposesStore[`${data.methodName}`];
  let ret = null;
  if (method) {
    if (data.params == null) {
      ret = method();
    } else {
      const params = JSON.parse(data.params);
      ret = method(params);
    }
  }
}

//绑定到window,方便调试
window.invoke = invoke;

export default {
  start(){
    // 监听基座下发的数据变化
    window.addEventListener("message", (e) => {
      if (e.data._action == "invoke") {
        invoke(e.data)
      } else if (e.data._action == "init") {
        //params.value = data.params;
      }
    })
  }
}
