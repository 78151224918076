<template>
  <div class="orderInformation">
    <el-form :model="ruleForm" ref="ruleForm" label-width="" id="demo-ruleForm">
      <el-form-item prop="companyName" class="demo-ruleForm"
                    :rules="{ required: true, message: '请输入企业名称，至少4个字', trigger: 'blur' }"
                    label="企业名称">
        <!-- <el-input v-model="ruleForm.companyName" placeholder="请输入企业名称，最少4个字"></el-input> -->
        <el-select v-model="companyName" filterable remote reserve-keyword
                   placeholder="请输入企业名称，最少4个字" ref="select"
                   @visible-change="cancalReadOnly" :remote-method="remoteMethod"
                   @change="nameChange"
                   @hook:mounted="cancalReadOnly" :loading="loading">
          <el-option v-for="(item, index) in options" :key="item.creditCode" :label="item.name"
                     :value="index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="nsrsbh" :rules="{ required: true, message: '请先搜索企业', trigger: 'blur' }"
                    label="社会信用代码">
        <el-input v-model="ruleForm.nsrsbh" placeholder="选择企业名称后自动带出，无需填写" disabled></el-input>
      </el-form-item>
      <el-form-item prop="time" :rules="{ required: true, message: '请选择结束日期', trigger: 'blur' }"
                    label="检测期间">
        <div class="timeSelect" @click="timeShow = true">
          <span>{{ time ? timeStart : "请先选择开始日期" }}</span>
          -
          <span>{{ time ? time : "结束日期" }}</span>
          <i class="iconfont icon-icon_deta"></i>
        </div>
        <DatetimePicker :values="time" @changeValue="timeShow = false" ref="popup"
                        :title="'请选择结束' + unitChinese"
                        :valueFormat="unit" :showPicker="timeShow" @confirm="selectTime" />
      </el-form-item>
      <el-form-item prop="bizType" :rules="{ required: true, message: '请选择业务类型', trigger: 'blur' }"
                    label="业务类型">
        <!-- <div class="enterpriseName"><i class="iconfont icon-icon_tips" style="margin-left:10px ;" @click="openlog('数据保留天数','不同类型企业的增值税税负率统计口径不同。<br>出口为主：增值税税负率=（当期销项-当期进项+进项转出+免抵退出口销售×适用税率+简易征收应纳税额）÷（适用税率销售+简易计税销售+免抵退出口销售）数据来源企业增值税申报表。<br>内销为主：增值税税负率=应纳税额÷适用税率销售收入。','知道了','#165DFF')"></i><span>*</span></div> -->
        <van-radio-group v-model="ruleForm.bizType">
          <van-radio :name="2">出口为主</van-radio>
          <van-radio :name="1" style="margin-left: 70px">内销为主</van-radio>
        </van-radio-group>
      </el-form-item>
      <el-form-item prop="remainDaysName"
                    :rules="{ required: true, message: '数据保留天数', trigger: 'change' }"
                    label="数据保留天数">
        <!-- <div class="enterpriseName"><i class="iconfont icon-icon_tips" style="margin-left:10px ;" @click="openlog('数据保留天数','本系统基于您的授权获取数据，并以此为基础生成风险检测报告。您可以选择数据保留期限，包括保留7天、保留14天、保留30天或不保留（您需要注意的是如果选择不保留数据将无法查看发票数据和申报表数据）','知道了','#165DFF')"></i><span>*</span></div> -->
        <!-- <el-select v-model="ruleForm.remainDays" placeholder="请选择数据保留天数">
          <el-option label="7天" :value="7"></el-option>
          <el-option label="14天" :value="14"></el-option>
          <el-option label="30天" :value="30"></el-option>
          <el-option label="不保留" :value="0"></el-option>
        </el-select> -->
        <!-- <el-input
          placeholder="请选择数据保留天数"
          suffix-icon="el-icon-arrow-down"
          v-model="ruleForm.remainDaysName" @focus.stop="inputfocus">
        </el-input> -->
        <div class="timeSelect" @click="inputfocus">
          <span>{{ ruleForm.remainDaysName ? ruleForm.remainDaysName : "请选择数据保留天数" }}</span>
          <i class="iconfont icon-icon_arrow_down"></i>
        </div>
        <van-popup get-container="body" v-model="isPicker" position="bottom">
          <!-- 时间选择 -->
          <div v-if="isPicker">
            <van-picker ref="picker" show-toolbar :title="'保留天数'" :columns="columns"
                        :default-index="defaultIndex"
                        @confirm="onConfirm" @cancel="cancelOn" />
          </div>
        </van-popup>
      </el-form-item>
      <!-- <div class="serviceAgreement">
        <van-checkbox v-model="checked" style="margin-right: 8px" @hook:mounted="cancalReadOnly"></van-checkbox>我已阅读并同意<span
          @click="privacyPolicy"
          >《授权协议》</span
        >
      </div> -->
      <div style="height: 0.64rem"></div>
    </el-form>
    <div class="btn-item" @click="submitForm('ruleForm')">下一步</div>
    <vantDialog ref="vantDialogRef" />
    <sqxy ref="sqxyRef"></sqxy>
  </div>
</template>
<script>
import {
  getChannelInfo,
  companySearch,
  getCompanyMsgByTaxNo,
  getUuid,
  getAreaMsg, saveCheckUser
} from "@/api/fri";
import vantDialog from "./components/vantDialog.vue";
import DatetimePicker from "./components/DatetimePicker.vue";
import dayjs, { Dayjs } from "dayjs";
import Sqxy from "./components/sqxy.vue";
import moment from "moment";
import "moment/locale/zh-cn";
import { startTesting } from "@/api/api"

moment.locale("zh-cn");
export default {
  metaInfo: {
    title: "在线检测",
  },
  components: { vantDialog, DatetimePicker, Sqxy },
  data () {
    return {
      list: [
        // {
        //   name: '7天',
        //   value: 7,
        // },
        {
          name: '30天',
          value: 30,
        },
        {
          name: '不保留',
          value: 0,
        },
      ],
      columns: ['30天', '不保留'],
      isPicker: false, //是否显示弹出层
      checked: true,
      loading: false,
      options: [],
      time: "",
      timeShow: false,
      goodInfo: null,
      companyName: null,
      ruleForm: {
        nsrsbh: "",
        companyName: "",
        time: "",
        bizType: "",
        remainDays: "",
        remainDaysName: '',
      },
      defaultIndex: '',
    };
  },
  mounted () {
    this.getGoodInfo();
  },
  computed: {
    paramsTimeStart () {
      if (this.goodInfo == null) return '';

      if (this.goodInfo.detectionDurationUnit == "YEAR") {
        const date = this.time - (this.goodInfo.detectionDuration - 1).toString() + "-01-01";

        return date;
      } else if (this.goodInfo.detectionDurationUnit == "MONTH") {
        let maxDayOfMonth;
        let year = this.time.split("-")[0] * 1;
        let month = this.time.split("-")[1] * 1;
        if (month === 4 || month === 6 || month === 9 || month === 11) {
          maxDayOfMonth = 30;
        } else if (month === 2) {
          if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            maxDayOfMonth = 29;

          } else {
            maxDayOfMonth = 28;

          }
        } else {
          maxDayOfMonth = 31;
        }
        const date = this.time + "-01"
        // const date = this.time + "-" + maxDayOfMonth.toString();

        return (
          new Date(date).getTime() -
          (this.goodInfo.detectionDuration - 1) * 30 * 24 * 60 * 60 * 1000
        );
        // return this.format(new Date(new Date(date).getTime()-((this.goodInfo.detectionDuration - 1) * 30 * 24 * 60 * 60 * 1000),true))
      } else if (this.goodInfo.detectionDurationUnit == "QUARTER") {
        let fieldToRemove = "季度"; // 要删除的字段
        let replaceSpan = this.time.replace(new RegExp(`${ fieldToRemove }`, "g"), "");
        let arr = replaceSpan.split("-");
        //季度开始
        let quarterStart;
        if (arr[1] * 1 == 4) {
          quarterStart = "10";
        } else if (arr[1] * 1 == 3) {
          quarterStart = "07";
        } else if (arr[1] * 1 == 2) {
          quarterStart = "04";
        } else {
          quarterStart = "01";
        }
        const date =
          new Date(arr[0] + "-" + quarterStart + "-01").getTime() -
          (this.goodInfo.detectionDuration - 1) * 30 * 3 * 24 * 60 * 60 * 1000;
        return this.format(new Date(date), true);
      }
    },
    paramsTimeEnd () {
      if (this.goodInfo == null) return '';

      if (this.goodInfo.detectionDurationUnit == "YEAR") {
        const date = this.time.toString() + "-12-31";
        return date;
      } else if (this.goodInfo.detectionDurationUnit == "MONTH") {
        let year = this.time.split("-")[0] * 1;

        let maxDayOfMonth;

        let month = this.time.split("-")[1] * 1;
        if (month === 4 || month === 6 || month === 9 || month === 11) {
          maxDayOfMonth = 30;
        } else if (month === 2 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
          maxDayOfMonth = 29;
        } else {
          maxDayOfMonth = 31;
        }

        const date = this.time + "-" + maxDayOfMonth.toString();
        return date;
      } else if (this.goodInfo.detectionDurationUnit == "QUARTER") {
        let fieldToRemove = "季度"; // 要删除的字段
        let replaceSpan = this.time.replace(new RegExp(`${ fieldToRemove }`, "g"), "");
        let arr = replaceSpan.split("-");
        let quarterEnd;
        if (arr[1] * 1 == 4) {
          quarterEnd = "12";
        } else if (arr[1] * 1 == 3) {
          quarterEnd = "09";
        } else if (arr[1] * 1 == 2) {
          quarterEnd = "06";
        } else {
          quarterEnd = "03";
        }
        let month;
        let maxDayOfMonth;
        let year = this.time.split("-")[0] * 1;

        month = quarterEnd * 1;
        if (month === 4 || month === 6 || month === 9 || month === 11) {
          maxDayOfMonth = 30;
        } else if (month === 2 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
          maxDayOfMonth = 29;
        } else {
          maxDayOfMonth = 31;
        }
        return arr[0] + "-" + quarterEnd + "-" + maxDayOfMonth;
      }
    },
    timeStart () {
      if (this.goodInfo == null) return;
      if (this.goodInfo.detectionDurationUnit == "YEAR") {
        return this.time * 1 - (this.goodInfo.detectionDuration - 1);
      } else if (this.goodInfo.detectionDurationUnit == "MONTH") {
        var times =
          new Date(this.time + "-01").getTime() -
          (this.goodInfo.detectionDuration - 1) * 30 * 24 * 60 * 60 * 1000;
        return this.format(new Date(times));
      } else if (this.goodInfo.detectionDurationUnit == "QUARTER") {
        let fieldToRemove = "季度"; // 要删除的字段
        let replaceSpan = this.time.replace(new RegExp(`${ fieldToRemove }`, "g"), "");
        let arr = replaceSpan.split("-");
        //季度开始
        let quarterStart = "03";
        if (arr[1] * 1 == 4) {
          quarterStart = "10";
        } else if (arr[1] * 1 == 3) {
          quarterStart = "07";
        } else if (arr[1] * 1 == 2) {
          quarterStart = "04";
        } else {
          quarterStart = "01";
        }

        var date =
          new Date(arr[0].toString() + "-" + quarterStart + "-01").getTime() -
          (this.goodInfo.detectionDuration - 1) * 30 * 3 * 24 * 60 * 60 * 1000;
        var realQuarter;
        if (new Date(date).getMonth() + 1 > 9) {
          realQuarter = new Date(date).getFullYear().toString() + "-4季度";
        } else if (new Date(date).getMonth() + 1 > 6) {
          realQuarter = new Date(date).getFullYear().toString() + "-3季度";
        } else if (new Date(date).getMonth() + 1 > 3) {
          realQuarter = new Date(date).getFullYear().toString() + "-2季度";
        } else {
          realQuarter = new Date(date).getFullYear().toString() + "-1季度";
        }
        return realQuarter;
      }
    },
    unitChinese () {
      if (this.goodInfo == null) return;
      if (this.goodInfo.detectionDurationUnit == "YEAR") {
        return "年份";
      } else if (this.goodInfo.detectionDurationUnit == "MONTH") {
        return "月份";
      } else if (this.goodInfo.detectionDurationUnit == "QUARTER") {
        return "季度";
      }
    },
    unit () {
      if (this.goodInfo == null) return;
      if (this.goodInfo.detectionDurationUnit == "YEAR") {
        return "yyyy";
      } else if (this.goodInfo.detectionDurationUnit == "MONTH") {
        return "yyyy-MM";
      } else if (this.goodInfo.detectionDurationUnit == "QUARTER") {
        return "yyyy-quarter";
      }
    },
  },
  methods: {
    cancalReadOnly (value) {
      this.$nextTick(() => {
        if (!value) {
          const { select } = this.$refs;
          const input = select.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
          const check = select.$el.querySelector(".el-checkbox__inner");
          check.removeAttribute("readonly");
          // this.$refs.select.blur();  根据tip自行判断是否添加


        }
      });
    },
    onConfirm (e) {
      let value = this.list.find(i => i.name == e)
      this.ruleForm.remainDays = value.value
      this.ruleForm.remainDaysName = e
      this.isPicker = !this.isPicker

    },
    cancelOn () {
      this.isPicker = !this.isPicker

    },
    inputfocus () {
      if (this.ruleForm.remainDaysName == '') {
        this.defaultIndex = 0
      } else {
        let value = this.list.findIndex(i => i.name == this.ruleForm.remainDaysName)
        this.defaultIndex = value

      }
      this.isPicker = !this.isPicker
    },
    // cancalReadOnly(onOff) {
    //   this.$nextTick(() => {
    //     if (!onOff) {
    //       const { select } = this.$refs;
    //       const input = select.$el.querySelector(".el-input__inner");
    //       input.removeAttribute("readonly");
    //     }
    //   });
    // },
    privacyPolicy () {
      this.$refs.sqxyRef.open();
    },
    //格式化时间
    format (dat, isshowDay) {
      //获取年月日，时间
      var year = dat.getFullYear();
      var mon = dat.getMonth() + 1 < 10 ? "0" + (dat.getMonth() + 1) : dat.getMonth() + 1;
      var data = dat.getDate() < 10 ? "0" + dat.getDate() : dat.getDate();
      var newDate = year + "-" + mon + "-" + "01";
      var newDateWithOutDay = year + "-" + mon;
      if (isshowDay) {
        return newDate;
      } else {
        return newDateWithOutDay;
      }
    },
    remoteMethod (query) {
      if (query.length < 4) return;
      const params = {
        keyword: query,
        pageNum: 1,
        pageSize: 999,
      };
      companySearch(params).then((res) => {
        this.options = res.data.list;
      });
    },
    nameChange (e) {
      this.ruleForm.nsrsbh = this.options[e].creditCode;
      this.ruleForm.companyName = this.options[e].name;
    },
    getGoodInfo () {
      this.goodInfo = JSON.parse(this.$route.query.goodInfo)
      /* let params = {
         id: this.$route.query.id,

       };
       getChannelInfo(params).then((res) => {
         if (res.code == 200) {
           this.goodInfo = res.data;
           // this.getCompanyList();
         }
       });*/
    },
    selectTime (val) {
      this.ruleForm.time = val;
      this.time = val;
    },
    openlog (title, message, buttonSpan, buttonColor) {
      this.$refs.vantDialogRef.open(title, message, buttonSpan, buttonColor);
    },
    async submitForm (e) {
      if (!this.checked) {
        this.$message({
          message: "请先阅读授权协议",
          type: "warning",
        });
        return;
      } else {
        this.$refs[e].validate(async (valid) => {
          if (valid) {
            // this.$router.push({
            //   path: "/dataAuthorization",
            // });
            const conmpanyMsgParams = {
              taxNo: this.ruleForm.nsrsbh,
            };
            const companyMsgRes = await getCompanyMsgByTaxNo(conmpanyMsgParams);
            if (companyMsgRes.code == "200") {
              const msgRes = await getAreaMsg(conmpanyMsgParams);

              let available = msgRes.data.available;
              let dataPeriod = "";
              if (available) {
                dataPeriod = 3;
              } else {
                dataPeriod = 0;
              }

              const params = {
                bizType: this.ruleForm.bizType || "",
                companyName: this.ruleForm.companyName,
                remainDays: this.ruleForm.remainDays,
                taxNo: this.ruleForm.nsrsbh,
                orgId: localStorage.getItem("organizationId"),
                goodId: this.$route.query.goodsId,
                endTime: moment(this.paramsTimeEnd).format("YYYY-MM-DD HH:mm:ss"),
                startTime: moment(this.paramsTimeStart).format("YYYY-MM-DD HH:mm:ss"),
                // zq: this.paramsTimeEnd ? this.paramsTimeEnd.split("-")[0] : '',
                source: 3,
                // uniqueId: localStorage.getItem("channelUniqueId"),
                skuId: this.$route.query.skuId
              };

              const res2 = await startTesting(params)
              if (res2.code == 200) {
                const checkRes = await saveCheckUser(res2.data.programId)
                if (checkRes.code == 200) {
                  this.$router.push({
                    name: "dataAuthorization",
                    params: {
                      dataPeriod: dataPeriod,
                      uuid: res2.data.programId,
                      reportId: res2.data.reportId,
                    },
                    query: {
                      info: JSON.stringify(params),
                      channelUniqueId: localStorage.getItem("channelUniqueId"),
                      code: this.$route.query.code
                    },
                  });
                }

              }


              // }
            }
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style>
.el-input .el-input__suffix {
  /* display: contents; */
}

.el-select-dropdown__item.selected {
  color: #165DFF !important;

}
</style>
<style lang="stylus" scoped>
>>> .el-input__inner::placeholder {
  color: #A4A6A9;
}
>>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
>>> .el-checkbox__inner::after {
  top: 3px;
  left: 7px;
}
>>> .van-hairline-unset--top-bottom.van-picker__frame {
  color: #165DFF;
}
.el-form-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start
  >>> .el-input.is-disabled .el-input__inner {
    background-color: #F4F6F8;
  }
  >>> .el-form-item__label {
    text-align: left;
    color: #1D2129;
    font-size: 14px;
  }
}
.serviceAgreement {
  display: flex;
  font-size: 0.28rem;
  margin: 0.5rem 0 0 0;
  color: #0E1429;
  line-height: 0.42rem;
  span {
    color: #3F74F6;
  }
}
.timeSelect {
  box-sizing: border-box;
  padding: 8px 12px;
  height: 40px;
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #DFDFE0;
  color: #A4A6A9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    line-height: 1;
  }
  > i {
    line-height: 1;
  }
}
>>> .van-radio-group {
  display: flex
}
.orderInformation {
  position relative
  width: 100vw;
  height: 100vh;
  background: #fff;
  padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  .enterpriseName {
    span {
      color: #fa4f4f;
      margin-left: 0.1rem;
      font-size: 0.28rem;
    }
  }
}
>>> .el-form-item {
  //display: flex;
  //flex-direction: column;
}
.btn-item {
  width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #3F74F6;
  border-radius: 0.1rem;
  opacity: 1;
  color: #fff;
  text-align: center;
  font-size: 0.34rem;
  position: absolute;
  bottom: 20px;
}
.el-icon-warning {
  margin-right: 0.16rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.28rem;
}
#demo-ruleForm {
  >>> .el-form-item__content {
    margin-left: 0rem !important;
  }
  >>> .el-form-item {
    margin-bottom: 0.25rem;
  }
  >>> .el-select {
    width: 6.9rem;
  }
}
</style>
