<template>
  <router-view :channelUniqueId="channelUniqueId" :posterUniqueId="posterUniqueId" />
</template>
<script>
import { wechatAuth, autoLogin, getCheckUser } from "@/api/fri";
import { getChannelUnifiedParam } from "@/api/api";
import {goToAuthorizePage} from "@/utils/help";
import {getUrlQuery} from "@/packages/RemoteService/helper";
const urlQuery = getUrlQuery();

export default {
  data () {
    return {
      loginCode: "",
      userData: null,
      channelUniqueId: '',
      posterUniqueId: '',
    };
  },
  methods: {},
  async created () {

    if (this.$route.meta?.type=='noAuthRequired') {
      return
    }


    //如果页面作为服务,不需要授权

    if(this.$route.meta?.type=='service'){
      // 从url中获取cookie-token,并存储到cookie中
      window.token = urlQuery.token;
      if (window.token){
        localStorage.setItem('token', window.token)
      }
      if (urlQuery.openId){
        localStorage.setItem('openId', urlQuery.openId)
      }

      return
    }
    // 因为授权成功之后重定向会回到首页，这个时候缓存会丢失，channelUniqueId不存在就不知道是哪个渠道商，但微信提供一个参数通过state参数将channelUniqueId携带到首页
    // 重新存入缓存
    //   http://testchannel.ai-tax.cn/scanTime/?unifiedParamId=c3d35ac40a2d437ebf437f597e507420
    if (this.$route.name == 'scanTime') {
      return;
    }
    if (this.$route.query.state) {
      localStorage.setItem("unifiedParamId", this.$route.query.state);

      const res = await getChannelUnifiedParam(this.$route.query.state)
      if (res.code == 200) {
        localStorage.setItem("channelUniqueId", res.data.channelUniqueId);
        this.channelUniqueId = res.data.channelUniqueId
        this.posterUniqueId = res.data.posterUniqueId
        localStorage.setItem("posterUniqueId", res.data.posterUniqueId);

      }
    }
    // 新用户扫码会带channelUniqueId这个参数，先存在缓存
    if (this.$route.query.unifiedParamId) {
      localStorage.setItem("unifiedParamId", this.$route.query.unifiedParamId);

      const res = await getChannelUnifiedParam(this.$route.query.unifiedParamId)
      if (res.code == 200) {
        localStorage.setItem("channelUniqueId", res.data.channelUniqueId);
        this.channelUniqueId = res.data.channelUniqueId
        this.posterUniqueId = res.data.posterUniqueId

        localStorage.setItem("posterUniqueId", res.data.posterUniqueId ? res.data.posterUniqueId : '');

      }
    }
    if (this.$route.name == 'boss-watch-report') {
      if (this.$route.params.token) {
        localStorage.setItem('token', this.$route.params.token)
        return;
      }
    }

    let token = localStorage.getItem("token");
    if (token) {
      localStorage.setItem("isMobile", false);
      let params = {
        channelUniqueId: this.channelUniqueId,
      };
      autoLogin(params).then(async (res) => {
        if (res.code == 200) {
          if (res.data.headImgUrl) {
            localStorage.setItem("headImgUrl", res.data.headImgUrl);
          }
          if (res.data.nickname) {
            localStorage.setItem("nickname", res.data.nickname);
          }
          if (res.data.phoneNumber) {
            localStorage.setItem("phoneNumber", res.data.phoneNumber);
          }
          if (res.data.currentOrganizationId) {
            localStorage.setItem("organizationId", res.data.currentOrganizationId);
          }
          if (this.$route.name != 'dataAuthorization') return
          const checkRes = await getCheckUser(this.$route.params.uuid)
          if (checkRes.code == 200) {
            if (checkRes.data) {

            } else {
              this.$router.push({
                path: "/my",
                query: {
                  channelUniqueId: params.channelUniqueId,
                  code: params.code

                },
              });
              return;
            }
          }
          // 因为微信授权取不到手机号，所有这里设计了一个弹窗，用户手动填写
          // if (res.data.phoneNumber==null) {
          //   this.isMobile = true;
          // }
        }
      });
      return;
    } else {
      // 用户首次进入
      console.log("用户首次进入");

      localStorage.setItem("isMobile", true);
      // 如果存在code,就用code去取一下参数，存在code说明已经从授权页面重定向过来
      this.loginCode = this.$route.query.code;
      if (this.loginCode) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        // 请求接口 用code换参数
        let params = {
          code: this.$route.query.code,
          channelUniqueId: this.channelUniqueId
        };

        if (this.$route.name == 'boss-watch-report') {
          if (this.$route.params.token) {
            localStorage.setItem('token', this.$route.params.token)
            return;
          }
        }


        wechatAuth(params).then(async (res) => {
          if (res.data.code == 200) {
            this.userData = res.data.data;
            localStorage.setItem("headImgUrl", this.userData.headImgUrl);
            localStorage.setItem("nickname", this.userData.nickname);
            localStorage.setItem("unionId", this.userData.unionId);
            localStorage.setItem("organizationId", this.userData.currentOrganizationId);

            //
            // 通过这边的参数判断需不需要填写手机号
            if (this.userData.phoneNumber == null) {
              // this.isMobile = true;
              localStorage.setItem("isMobile", true);
            } else {
              // 存下token
              // this.isMobile = false;
              if (this.$route.name == 'dataAuthorization') {
                const checkRes = await getCheckUser(this.$route.params.uuid)
                if (checkRes.code == 200) {
                  if (checkRes.data) {

                  } else {
                    this.$router.push({
                      path: "/my",
                      query: {
                        channelUniqueId: params.channelUniqueId,
                        code: params.code

                      },
                    });
                    return;
                  }
                }
              }


              localStorage.setItem('isMobile', false)

              localStorage.setItem("token", res.headers["authorization"]);
              localStorage.setItem("phoneNumber", this.userData.phoneNumber);
            }
          } else {
            console.log("1")
            goToAuthorizePage({

              unifiedParamId: localStorage.getItem("unifiedParamId"),

            })
          }
          loading.close();
        });
      } else {
        console.log("2")
        // this.isMobile = false;
        localStorage.setItem("isMobile", false);
        goToAuthorizePage({
          unifiedParamId: localStorage.getItem("unifiedParamId"),
        })
      }
    }
  },
};
</script>
<style>
html,
body {
  padding: 0;
  margin: 0;
  font-family: PingFang SC-Regular, PingFang SC;
}
body {
  background: #f5f7fa;
  font-size: 0.32rem;
  -webkit-text-size-adjust: 100% !important;
}
</style>
