<script>
import { getCornerPic } from '@/utils/help.js'
export default {
  name: "ATestLog",
  components: {},
  data(){
    return {
      dataPage:[],
      userInfo:{},
      statusList: new Map([[0, '生成中'], [1, '生成中'], [2, '已生成'], [-2, '已删除'], [3, '未授权']]),
    }
  },
  props:{
    item:Object
  },

  async mounted() {
  },
  computed: {},
  methods:{
    getCornerPic,
    clickStatusTip (v) {
      // console.log();
      // if (v.goodsId != 20) {
      //   this.show = true;
      //   return;
      // }

      if (v) {
        localStorage.setItem("goodsName", v.goodsName);
      }
      if (v.status == 2) {
        if (v.goodsId == 20) {
          //去老板看报告
          this.$router.push({
            path: "/userOnly/boss-watch-report/" + v.programId + "/" + v.reportId,
            query: {
              // id: this.goodsList.policyId,
              channelUniqueId: localStorage.getItem("channelUniqueId"),
              code: this.$route.query.code,
            },
          });
          return;
        } else {
          console.log(v);
          this.loginUrl = baseEnv + 'reportCenter/' + v.reportId + '?token=' + localStorage.getItem('token') + '&desensitizationStatus=0'
          window.open(this.loginUrl)
          // this.loginUrl = baseEnv;
          // this.reportId=v.id
          // this.vanPopupShareShow = true;
          return;
        }
      } else if (v.status == 0 || v.status == 1 || v.status == 3) {
        const params = {
          companyName: v.taxName,
          taxNo: v.taxNo,
          orgId: localStorage.getItem("organizationId"),
          goodId: v.goodsId,
          zq: v.zqId.replace('004', ''),
          source: 3,
          uniqueId: localStorage.getItem("channelUniqueId"),
          endTestingDate: v.fxqjZ,
          startTestingDate: v.fxqjQ,
        };
        this.$router.push({
          name: "dataAuthorization",
          params: {
            uuid: v.programId,
            reportId: v.reportId,
          },
          query: {
            info: JSON.stringify(params),
            channelUniqueId: localStorage.getItem("channelUniqueId"),
          },
        });
        return;

      }
      this.showReportTitle = true;
    },
  }
}
</script>

<template>
  <div>
      <div class="cardTime" @click="clickStatusTip(item)">
        <div class="haibaoPic">
          <img class="backPic" :src="item.showImageUrl" alt="" />
          <img class="version-logo"
               :src="getCornerPic(item.attrs)"
               alt="">
        </div>
        <div style="margin-left: 0.3rem">
          <div class="timeOne">{{ item.taxName }}</div>
          <div class="timeTwo">
            {{ item.goodName }}
          </div>
          <div class="timeThree">{{ item.createTime }}</div>
        </div>
        <div class="status">
          <div class="statusBlue" v-if="item.status == 0 || item.status == 1">
            <div class="statusCircleBlue"></div>
            {{ statusList.get(item.status)}}
          </div>
          <div class="statusGray" v-else-if="item.status ==-2 ">
            <div class="statusCircleGray"></div>
            {{ statusList.get(item.status)}}
          </div>
          <div class="statusYellow" v-else-if="item.status ==3 ">
            <div class="statusCircleYellow"></div>
            {{ statusList.get(item.status)}}
          </div>
          <div class="statusGreen" v-else>
            <div class="statusCircleGreen"></div>
            {{ statusList.get(item.status)}}
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="stylus">
.cardTime {
  display: flex
  .timeOne {
    font-size: 0.28rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #0E1429;
    margin-top: 0.1rem;
    width: 3.38rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .timeTwo, .timeThree {
    font-size: 0.24rem;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    color: #606368;
    margin-top: 0.2rem;
  }
  .status {
    position: absolute;
    right: 0.5rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    margin-top: 0.1rem;
    .statusYellow {
      color: #FAAD14;
      display: flex;
      align-items: center;
    }
    .statusCircleYellow {
      width: 0.12rem;
      height: 0.12rem;
      background: #FDB320;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .statusGreen {
      color: #52C41A;
      display: flex;
      align-items: center;
    }
    .statusYellow {
      color: #FDB320;
      display: flex;
      align-items: center;
    }
    .statusCircleGreen {
      width: 0.12rem;
      height: 0.12rem;
      background: #52C41A;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .statusRed {
      color: #F5222D;
      display: flex;
      align-items: center;
    }
    .statusCircleRed {
      width: 0.12rem;
      height: 0.12rem;
      background: #F5222D;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .statusBlue {
      color: #3F74F6;
      display: flex;
      align-items: center;
    }
    .statusGray {
      color: #a4a6a9;
      display: flex;
      align-items: center;
    }
    .statusGreen {
      color: #10CC99;
      display: flex;
      align-items: center;
    }
    .statusCircleBlue {
      width: 0.14rem;
      height: 0.14rem;
      background: #3F74F6;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .statusCircleGray {
      width: 0.14rem;
      height: 0.14rem;
      background: #a4a6a9;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .statusCircleGreen {
      width: 0.14rem;
      height: 0.14rem;
      background: #10CC99;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .statusCircleYellow {
      width: 0.12rem;
      height: 0.12rem;
      background: #FDB320;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .statusGray {
      color: rgba(14, 20, 41, 0.4);
      display: flex;
      align-items: center;
    }
    .statusCircleGray {
      width: 0.12rem;
      height: 0.12rem;
      background: rgba(14, 20, 41, 0.4);
      border-radius: 50%;
      margin-right: 0.1rem;
    }
  }
}
.pay-bottom {
  height: 0.48rem;
  .pay-btn {
    width: 1.28rem;
    background: #fff;
    border-radius: .1rem;
    border: 1px solid rgba(14, 20, 41, 0.2);
    text-align: center;
    color: #0E1429;
    font-size: .24rem;
    box-sizing: border-box;
    padding: 0.09rem 0;
    position: absolute;
    right: 0.47rem;
  }
}
</style>
