<script>
import {formatToDecimal} from "@/utils/format";

export default {
  name: "FancyDecimal",
  props:{
    value:Number
  },
  computed:{
    integerPart(){
      let ret = this.value.toString().replace(/\.\d+$/,'')
      ret = formatToDecimal(parseInt(ret))
      ret = ret.replace(".00","")
      return ret;
    },
    fragmentPart(){
      let ret = '.00'
      if (this.value.toString().includes('.')){
        ret = this.value.toString().replace(/^[\-0-9,]+./,'.')
        ret = ret.padEnd(3,"0")
      }

      return ret;
    }
  }

}
</script>

<template>
<span class="fancy-decimal-wrapper" v-if="value!=null">
  <span class="unit" style="font-size: 0.24rem">￥</span>
  <template v-if="value===0">
    <span class="integer">0</span>
  </template>
  <template v-else>
    <span class="integer">{{integerPart}}</span>
    <span class="fragment" v-if="fragmentPart">{{fragmentPart}}</span>
  </template>
</span>
</template>

<style scoped lang="stylus">
.fancy-decimal-wrapper{
  display block
  line-height 1.1
  margin-right 0px;
  font-weight: 700;
  .integer{
    font-size: .36rem;
  }
  .fragment{
    font-size: .24rem;
  }
}
</style>
