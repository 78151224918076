/**
 * @description 生成随机字符串
 * @param {number} length
 */
export function getRandomString(length = 10) {
  const str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += str.charAt(Math.floor(Math.random() * str.length))
  }
  return result
}


/**
 * 获取url参数对象
 * @returns {{}}
 */
export function getUrlQuery() {
  const urlParams = new URLSearchParams(window.location.search);
  const ret = {};
  urlParams.forEach((v, key) => {
    if (key.endsWith("[]")) {
      const keyArr = key.substring(0, key.length - 2);
      if (ret[keyArr]) {
        ret[keyArr].push(v);
      } else {
        ret[keyArr] = [v];
      }
    } else {
      ret[key] = v;
    }
  });
  return ret;
}
