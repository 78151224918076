<template>
  <div class="home-page">
    <img :src="posterUrl" alt="" />
    <div class="cost" @click="getBaseInfos">授权登录</div>
  </div>
</template>

<script>
import login from "./components/login.vue";
import { getProductListByChannelUniqueId } from "@/api/fri";

export default {
  components: {
    login,
  },
  data() {
    return {
      loginType: 1,
      wxCode: "",
      posterUrl: "",
    };
  },
  mounted() {
    // this.getProductLis();
    this.getBaseInfos();
  },
  methods: {
    getBaseInfos() {
      const base = process.env.VUE_APP_PDF_RE;
      const jumperProxy = process.env.VUE_APP_JUMP_PROXY;
      if (this.isWeiXin()) {
        const unifiedParamId =
          this.$route.query.unifiedParamId || localStorage.getItem("unifiedParamId");
        const code = this.getUrlParam("code"); // 截取路径中的code
        // 这里重定向的地址格式是微信文档给的  微信授权成功后携带code以及state参数(这里state参数放的是channelUniqueId)
        if (code == null || code === "") {
          //let url = base;
          let url = `${jumperProxy}/jump?to=${base}`;
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4de932054829ef8c&redirect_uri=" +
            encodeURIComponent(url) +
            "&response_type=code&scope=snsapi_userinfo&state=" +
            unifiedParamId +
            "&connect_redirect=1#wechat_redirect";
        } else {
        }

        if (code != "" && code != null) {
          this.wxCode = code;
        }
      } else {
      }
    },
    //判断是不是微信浏览器。
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    // 编码函数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    },
    //
    getProductLis() {
      let params = {
        channelUniqueId:localStorage.getItem("channelUniqueId"),
        posterUniqueId:localStorage.getItem("posterUniqueId"),

      };
      getProductListByChannelUniqueId(params).then((res) => {
        if (res.code == 200) {
          this.posterUrl = res.data[0].posterUrl;
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.home-page {
  // width: 100%;
  // height: 100vh;
  // background: url("../assets/img_bg.png") no-repeat center 0px;
  // background-size: cover;
  // position: relative;
  // font-size: 0.32rem;
  padding-bottom: 0;

  img {
    width: 100%;
  }
}

.home-login {
  position: absolute;
  bottom: 0;
}

.order, .cost {
  width: 5rem;
  height: 0.8rem;
  line-height: 0.8rem;
  background: rgba(63, 116, 246, 0.2);
  border-radius: 0.1rem;
  color: #3F74F6;
  font-size: 0.32rem;
  text-align: center;
}

.cost {
  position: fixed;
  bottom: 20px;
  left: 50%;
  margin-left: -2.5rem;
  background: #3F74F6;
  opacity: 1;
  color: #fff;
}
</style>
