<template>
  <div class="login">
    <div class="title">
      <div class="productName">{{ goodsList.productName }}</div>
      <div class="price">
        ￥
        <span class="price-title">{{ goodsList.currentPrice }}</span>
        <!--        <span class="price-title price-unit">起</span>-->
      </div>
    </div>
    <div class="btn-wrapper">
      <div class="my" @click="toIndex">
        <!-- <i class="el-icon-user"></i> -->
        <!-- <img class="icon-wode" src="@/assets/icon_wode.png" alt="" /> -->
        <i class="iconfont icon-icon_wode" style="color: #165dff"> </i>
        <div style="margin-top: 0.08rem">我的</div>
      </div>
      <!-- <div class="order" v-if="goodsList.tryType == 1" @click="toApproval">预约试用</div> -->
      <!-- <div class="cost" @click="toPurchase">立即购买</div> -->
      <div class="cost2 toTest" @click.stop="toTest">
        <p>立即检测</p>
        <p class="times">({{ goodsList.times }}次)</p>
      </div>
      <div class="cost2" @click.stop="toPurchase">{{goodsList.currentPrice?'立即购买':'免费领取'}}</div>
    </div>
  </div>
</template>
<script>
import { autoLogin, getTitleApi } from "@/api/fri";
import { Toast } from 'vant';
import {goToAuthorizePage} from "@/utils/help";

export default {
  metaInfo: {
    title: localStorage.getItem("titleName"),
  },
  props: ["goodsList"],
  data () {
    return {
      token: "",
      titleName: "",
    };
  },
  mounted () {
    let token = localStorage.getItem("token");
    this.token = token;
    let channelUniqueId = localStorage.getItem("channelUniqueId");
    getTitleApi(channelUniqueId).then((res) => {
      if (res.code == 200) {
        this.titleName = res.data;
        localStorage.setItem("titleName", res.data);
        document.title = res.data;
      }
    });
    this.init();
  },

  beforeRouteEnter (to, from, next) {
    let channelUniqueId = localStorage.getItem("channelUniqueId");
    if (to.name != from.name) {
      getTitleApi(channelUniqueId).then((res) => {
        if (res.code == 200) {
          document.title = res.data;
          localStorage.setItem("titleName", res.data);
          next();
        } else {
          this.$message.error(res.message);
        }
      });
    }

    getTitleApi(channelUniqueId).then((res) => {
      if (res.code == 200) {
        localStorage.setItem("titleName", res.data);
        document.title = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
    next();
  },
  methods: {
    toTest () {
      if (this.goodsList.times < 1) {
        Toast('可用检测次数为0，请先购买。');
        return;
      }
      this.$router.push({
        path: "/orderInformation",
        query: {
          goodInfo: JSON.stringify(this.goodsList),
          skuId: this.goodsList.skuId,
          goodsId: this.goodsList.goodsId,
          channelUniqueId: localStorage.getItem("channelUniqueId"),
          code: this.$route.query.code,
        },
      });
    },
    init () {
      let channelUniqueId = localStorage.getItem("channelUniqueId");
      getTitleApi(channelUniqueId).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("titleName", res.data);
          document.title = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    toPurchase () {
      // 判断是否已经授权
      // 1 已经授权 到详情页
      // 2 未授权 去授权页
      let token = localStorage.getItem("token");
      this.token = token;
      if (!token) {
        goToAuthorizePage({
            channelUniqueId: localStorage.getItem("channelUniqueId"),

        });
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        // let phoneNumber = localStorage.getItem("phoneNumber");
        // if (phoneNumber == null) {
        //   this.$emit("addPhone");
        // } else {
        //   this.$router.push({
        //     path: "/order",
        //     query: {
        //       id: this.goodsList.id,
        //     },
        //   });
        // }
        let params = {
          channelUniqueId: localStorage.getItem("channelUniqueId"),
        };
        autoLogin(params).then((res) => {
          loading.close();
          if (res.code == 200) {
            if (res.data.headImgUrl) {
              localStorage.setItem("headImgUrl", res.data.headImgUrl);
            }
            if (res.data.nickname) {
              localStorage.setItem("nickname", res.data.nickname);
            }
            if (res.data.phoneNumber) {
              localStorage.setItem("phoneNumber", res.data.phoneNumber);
            }
            if (res.data.currentOrganizationId) {
              localStorage.setItem("organizationId", res.data.currentOrganizationId);
            }
            if (!res.data.phoneNumber) {
              this.$emit("addPhone");
            } else {
              this.$router.push({
                name: "order",
                query: {
                  id: this.goodsList.policyId,
                  channelUniqueId: localStorage.getItem("channelUniqueId"),
                  code: this.$route.query.code,
                  goodInfo: JSON.stringify(this.goodsList),

                },
              });
            }
          }
        });
      }
    },
    // toApproval() {
    // 判断是否已经授权
    // 1 已经授权 到详情页
    // 2 未授权 去授权页
    // let token = localStorage.getItem("token");
    // this.token = token;
    // if (!token) {
    //   this.$router.push({
    //     path: "/authorize",
    //     query: {
    //       channelUniqueId: this.$route.query.channelUniqueId,
    //     },
    //   });
    // } else {
    //   this.$router.push({
    //     path: "/approval",
    //   });
    // }
    // },
    toIndex () {
      // 判断是否已经授权
      // 1 已经授权 到详情页
      // 2 未授权 去授权页
      let token = localStorage.getItem("token");
      this.token = token;
      if (!token) {
        goToAuthorizePage({
            channelUniqueId: localStorage.getItem("channelUniqueId"),
          });
      } else {
        this.$router.push({
          path: "/my",
          query: {
            channelUniqueId: localStorage.getItem("channelUniqueId"),
            code: this.$route.query.code,
          },
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.login {
  width: 100%;
  // height: 2.5rem;
  background: #FFFFFF;
  border-radius: 0.24rem 0.24rem 0 0;
  opacity: 1;
  font-size: 0.3rem;
  color: #0E1429;
  box-sizing: border-box;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.07rem;
    border-bottom: 1px solid rgba(14, 20, 41, 0.1);
    padding: 0 0.3rem;
    height: 1.07rem;
    .productName {
      font-size: 0.3rem;
      color: #1D2129;
    }
    .price {
      color: #165DFF;
      .price-title {
        font-family: DIN Alternate-Bold, DIN Alternate;
        font-size: 0.48rem;
        color: #165DFF;
        font-weight: bold;
        &.price-unit {
          font-size: 0.3rem;
          font-weight: normal;
        }
      }
    }
  }
  .btn-wrapper {
    display: flex;
    //align-items: center;
    //margin: 0.16rem 0;
    height: 1.12rem;
    padding: 0.16rem 0.3rem;
    justify-content: space-between;
    box-sizing: border-box;
    .my {
      text-align: center;
      font-size: 0.22rem;
      color: #165DFF;
      //margin: 0 0.65rem;
      margin-left: 0.3rem;
      .el-icon-user {
        margin-bottom: 0.1rem;
      }
      .icon-wode {
        font-size: 0.36rem;
        color: #1D2129;
      }
      .icon-icon_wode {
        font-size: 0.36rem;
        color: #1D2129;
      }
    }
    .order, .cost {
      width: 2.58rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: rgba(63, 116, 246, 0.2);
      border-radius: 0.1rem;
      color: #3F74F6;
      font-size: 0.32rem;
      text-align: center;
    }
    .cost {
      margin-left: 0.3rem;
      background: #3F74F6;
      opacity: 1;
      color: #fff;
    }
    .cost2 {
      width: 2.56rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #165DFF;
      opacity: 1;
      color: #fff;
      font-size: 0.32rem;
      text-align: center;
      font-weight: 400;
      border-radius: 0.1rem;
    }
    .cost2.toTest {
      line-height: 0.05;
      background: transparent;
      border: 1px solid #165DFF;
      color: #165DFF;
      > .times {
        font-size: 10px;
        color: #A4A6A9
      }
    }
  }
}
</style>
