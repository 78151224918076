import { render, staticRenderFns } from "./OrderRadio.vue?vue&type=template&id=642acc62&scoped=true&"
import script from "./OrderRadio.vue?vue&type=script&lang=js&"
export * from "./OrderRadio.vue?vue&type=script&lang=js&"
import style0 from "./OrderRadio.vue?vue&type=style&index=0&id=642acc62&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642acc62",
  null
  
)

export default component.exports