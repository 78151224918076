<template>
  <div class="pay" @click="toDetail(item.orderNo)">
    <div class="pay-top">
      <div class="haibaoPic">
        <img class="backPic" :src="item.showImageUrl" alt="" />
        <img class="version-logo"
             :src="getCornerPic(item.attrs)"
             alt="">
      </div>
      <div style="display: flex; width: 4.7rem; justify-content: space-between">
        <div style="display: flex;flex-direction: column;justify-content: space-between;">
          <span class="pay-title">
            <span class="pay-titles">{{ item.productName }}</span>
            <template v-if="type == 2">
              <img v-if="item.orderStatus == -1" class="yizhifu" src="@/assets/img_jygb.svg"
                   alt="" />
              <img v-if="item.orderStatus == 1" class="yizhifu" src="@/assets/img_dzf.svg" alt="" />
              <img v-if="item.orderStatus == 2" class="yizhifu" src="@/assets/img_jywc.svg"
                   alt="" />
            </template>
          </span>
          <span class="pay-price">
            <span class="productUnitPrice" :class="{ productUnitPrice20: type == 2 }">

                <FancyDecimal :value="item.price"></FancyDecimal>
                <span class="goodsCount" v-if="this.$route.name != 'detail'"
                > x{{ item.goodsCount }}</span
                >
              </span>

            </span>
          <span class="pay-del" v-if="type == 2">×{{ item.goodsCount }}</span>
        </div>
        <div class="pay-status" v-if="type != 2">
          <div class="status-top">
            <template v-if="item.orderStatus == -1">
              <div class="pay-status-icon" style="background: #F54242"></div>
              <div class="pay-status-txt" style="color: #F54242; ">已取消</div>
            </template>
            <template v-else-if="item.orderStatus == 1">
              <div class="pay-status-icon" style="background: #FDB320"></div>
              <div style="color: #FDB320">待支付</div>
            </template>
            <template v-else-if="item.orderStatus == 2">
              <div class="pay-status-icon" style="color: #10CC99"></div>
              <div style="color: #10CC99">已完成</div>
            </template>
          </div>
          <div class="status-bottom" style="display: flex;align-items: flex-end">
            <FancyDecimal :value="item.totalAmount"></FancyDecimal>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-bottom" v-if="type != 2">
      <div class="pay-time">{{ item.createTime }}</div>
      <div class="pay-btn" v-if="item.orderStatus == 1">立即支付</div>
    </div>
<!--    <div class="pay-total" v-if="this.$route.name != 'my'">-->
<!--      <div class="txt">应付金额</div>-->
<!--      &lt;!&ndash; <div class="txtMoney">￥{{ item.totalAmount }}</div> &ndash;&gt;-->
<!--      <div class="txtMoney" style="color: #165dff !important;display: flex;align-items: flex-end">-->
<!--        <FancyDecimal :value="item.totalAmount"></FancyDecimal>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
import FancyDecimal from "@/components/FancyDecimal.vue";
import { getAttrVal, getCornerPic } from '@/utils/help.js'

export default {
  components: { FancyDecimal },
  props: {
    type: [Number, String],
    item: [Object, Array]
  },
  data () {
    return { num: 1, floatPrice: "" };
  },
  computed: {},
  methods: {
    getAttrVal, getCornerPic,
    toDetail (orderNo) {
      this.$router.push({
        name: "wx-detail",
        query: {
          orderNo: orderNo,
        },
      });

    },
  },
};
</script>
<style lang="stylus" scoped>
.pay {
  width: 7.1rem;
  // height: 2.76rem;
  background: #FFFFFF;
  border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
  opacity: 1;
  font-size: 0.24rem;
  padding: 0.2rem;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
  .pay-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
    border-top: 1px solid #E6E8EA;
    margin-top: 0.2rem;
    .txt {
      line-height: 0.4rem;
    }
    .txtMoney {
      color: #165DFF;
      vertical-align: baseline;
      display: flex;
    }
    .floatPrice {
      margin-top: 0.12rem;
    }
  }
  .pay-top {
    display: flex;
    justify-content: space-between;
    .pay-title {
      position: relative;
      font-size: 0.28rem;
      font-weight: 600;
      color: #0E1429;
      //margin-top: 0.14rem;
      //height: 0.86rem;
      .pay-titles {
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .yizhifu {
        position: absolute;
        top: 0;
        right: 0rem;
        width: 1.14rem;
      }
    }
    .pay-price {
      display: flex;
      vertical-align: baseline;
      align-items: center;
      font-weight: 600;
      font-size: 0.36rem;
      //line-height: 0.42rem;
      color: #3F74F6;
      //margin-top: 0.1rem;
      .pay-del {
        display: flex;
        align-items: center;
        color: #A4A6A9;
        font-size: 0.28rem;
        font-weight: 400;
      }
      .productUnitPrice {
        display: flex;
        width: 2rem;
        color: #1D2129;
        align-items: flex-end;
        font-size: 0.24rem;
        line-height: 1
        .price-title {
          display: flex;
          font-family: DIN Alternate-Bold, DIN Alternate;
          font-size: 0.36rem;
          font-weight: bold;
          margin-left: 0.05rem;
        }
        .goodsCount {
          font-weight: 400;
          color: #A4A6A9;
          font-size: 0.24rem;
          margin-top: 0.05rem;
        }
      }
      .productUnitPrice20 {
        margin-right: 2.4rem;
      }
    }
    .pay-status {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      align-self: start;
      color: #18CC87;
      flex-direction: column;
      height: 100%;
      .status-top {
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        .pay-status-icon {
          width: 0.14rem;
          height: 0.14rem;
          background: #18CC87;
          opacity: 1;
          border-radius: 50%;
          margin-right: 0.14rem;
        }
        .pay-status-txt {
          color: rgba(250, 79, 79, 1);
        }
      }
      .status-bottom {
        height: 0.33rem;
        font-size: 0.24rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #1D2129;
        display: flex;
        margin-top: 0.1rem;
        span {
          font-weight: bold;
          color: #1D2129;
          font-size: 0.24rem;
        }
      }
    }
  }
  .pay-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
    .pay-time {
      font-size: 0.26rem;
      color: #A4A6A9;
      line-height: 0.37rem;
    }
    .pay-btn {
      width: 1.44rem;
      line-height: .34rem;
      border-radius: .1rem;
      opacity: 1;
      border: 1px solid #165DFF;
      background #165DFF;
      text-align: center;
      color: #fff;
      font-size: .26rem;
      box-sizing: border-box;
      padding: 0.09rem 0;
    }
  }
  .pay-bottom-text {
    display: flex;
    align-items: baseline;
    width: 6.5rem;
    background: rgba(253, 179, 32, 0.08);
    border-radius: 0.1rem;
    opacity: 1;
    margin-top: 0.3rem;
    color: #FDB320;
    font-size: 0.24rem;
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;
    box-sizing: border-box;
    line-height: 1.7;
    .el-icon-warning-outline {
      margin-right: 0.1rem;
    }
  }
}
.intPrice {
  font-size: 0.36rem;
  font-weight: bold;
  color: #1D2129;
  font-family: DIN Alternate-Bold, DIN Alternate;
}
.floatPrice {
  font-size: 0.24rem;
  font-weight: bold;
  color: #1D2129;
  margin-top: 0.04rem;
}
.icon {
  margin-top: 0.05rem;
}
</style>
