<script>
import {getShareInfo} from "@/api/wx";

export default {
  name: "MyShares",
  metaInfo: {
    title: "我的分享",
  },
  data() {
    return {
      shareInfos: [],
      loaded:false
    }
  },
  async mounted() {
    this.loaded = false;
    await this.loadShareInfo();
    this.loaded = true;
  },
  methods:{
    async loadShareInfo(){
      const res = await getShareInfo()
      this.shareInfos = res.data
    }
  }

}
</script>

<template>
  <div v-if="loaded">
    <div class="nullPic" v-if="!(shareInfos&&shareInfos.length>0)">
      <img src="@/assets/暂无数据.png" alt="" />
      还没有分享成功的次数哦～
      <div class="subtext">分享给好友，好友登录成功后你可获得咨询次数。</div>
    </div>
    <div style="padding: 0 .2rem" v-else>
      <div class="subtitle">分享成功的次数</div>
      <div class="menu-wrapper">
        <div class="help" v-for="(item,index) in shareInfos" :key="index">
          <div class="row">
            <div class="text">
              {{item.date}}
            </div>
            <div class="num">
              {{item.num}}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<style scoped lang="stylus">
.subtitle{
  font-family: PingFang SC;
  color: #606368;
  font-weight: 400;
  font-size: .30rem;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  margin-top 0.4rem;
  margin-bottom .24rem

}
.menu-wrapper{
  width: 100%;
  margin: 0 auto .60rem;
  border-radius: .16rem;
  background-color: #FFFFFF;
}
.help {
  padding: 0 .32rem;
  .row {

    display: flex;
    justify-content: space-between;
    height: .90rem;
    align-items: center;
    flex-grow: 1;

    opacity: 0.9;
    font-family: PingFang SC;
    color: #1D2129;
    font-weight: 400;
    font-size: .30rem;
    font-style: normal;
    text-decoration: none;
    text-align: left;
    border-bottom: 1px solid #DFDFE0;
    .text,.num{
      color: #1D2129;
      font-weight: 400;
      font-size: .30rem;
      font-style: normal;
      text-decoration: none;
      text-align: right;
    }

  }

  &:last-child{
    .row{
      border-bottom 0 none
    }
  }

  .help-icon {
    margin-right: .24rem;
    font-size: .36rem;
    color: #1D2129;
  }

  .right-icon {
    font-size: .28rem;
    color: #606368;
  }
}

.nullPic {
  display: flex;
  flex-direction: column;
  align-items: center
  margin-top: 3.2rem;
  font-size: 0.3rem
  img {
    width: 3.6rem;
    height: 3.6rem;
  }
  .subtext{
    margin-top .3rem
    color: #606368;
    font-weight: 400;
    font-size: .26rem
  }
}

</style>
